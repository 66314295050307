<form class="form-detail" [formGroup]="formInput" nz-form>
  <div class="group no-padding">
    <!-- header -->
    <div class="group-header" style="display: flex; align-items: center">
      <div style="text-wrap: nowrap !important">Customer Information</div>
      <i
        *ngIf="shouldShowHistory"
        style="margin-left: 10px"
        class="clickable"
        nz-icon
        nzType="history"
        nzTheme="outline"
        nz-tooltip
        nzTooltipTitle="View Changes History"
        (click)="goToHistoryScreen(model, routeAdminClientList)"
      ></i>
      <div style="width: 100%"></div>
      <ng-container *ngIf="requirePermissions([PermissionCode.client.update])">
        <!-- edit button -->
        <button nz-button (click)="onBtnEdit()" class="btn-primary" *ngIf="!isEditing">
          <i nz-icon nzType="edit" nzTheme="outline"></i>
          Edit
        </button>
        <!-- save button -->
        <button
          nz-button
          (click)="onBtnSave()"
          class="btn-primary"
          *ngIf="isEditing"
          [disabled]="onProgress || !needUpdate"
          [nzLoading]="onProgress"
        >
          <i nz-icon nzType="save" nzTheme="outline"></i>
          Save
        </button>
      </ng-container>
    </div>
    <!-- form -->
    <div class="group-content">
      <div class="tdm-row cell-spacing">
        <div class="tdm-col tdm-col-2" *ngFor="let key of ['name', 'website']">
          <div>
            <div class="form-label-v2">
              {{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span>
            </div>
            <nz-form-item>
              <nz-form-control>
                <input
                  nz-input
                  [formControlName]="key"
                  [type]="getInputType(key)"
                  [placeholder]="getPlaceHolder(key)"
                  (input)="onInputChanged($event, key)"
                />
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
      </div>

      <ng-container *ngFor="let key of ['address']">
        <div class="form-label-v2">
          {{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span>
        </div>
        <nz-form-item>
          <nz-form-control>
            <form-address [formControlName]="key" [disableInput]="!isEditing"></form-address>
          </nz-form-control>
        </nz-form-item>
      </ng-container>

      <button nz-button *ngIf="isEditing" (click)="onBtnAddLocation()">Add Location</button>
    </div>
  </div>
</form>
