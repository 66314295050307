
import { Component } from '@angular/core';
import { BaseDetail } from '../base/detail';
import { ActivatedRoute } from '@angular/router';
import { ApiMethod } from '@app/enum';
import { Const } from '@const/Const';
import { InputHelper } from '@services/input-helper';

@Component({
  selector: '[driver-detail]',
  templateUrl: './detail.html',
  styleUrls: ['../detail.scss', './detail.scss']
})
export class DriverDetail extends BaseDetail {
  protected formGroupDeclaration: FormGroupDeclaration = {
    firstName: {label: 'First name', required: true},
    lastName: {label: 'Last name', required: true},
    carrierId: {label: 'Carrier', required: true},
    phone: {label: 'Phone', inputType: 'tel', required: true, getValue: InputHelper.getValuePhone, formatValue: InputHelper.formatPhone},
    tractorNumber: {label: 'Tractor Number'},
    trailerNumber: {label: 'Trailer Number'},
    isNotifyViaSMS: {label: 'Driver has opted in to receive text messages', type: 'boolean', initialValue: true },

  };

  public get keys() {
    if (this.isAdmin) {
      // for admim
      return ['firstName', 'lastName', 'carrierId', 'phone', 'tractorNumber', 'trailerNumber'];
    } else if (this.isCarrier) {
      // for carrier
      return ['firstName', 'lastName', 'phone', 'tractorNumber', 'trailerNumber'];
    } else {
      return [];
    }
  }
  protected get crudEntity(): string { return 'drivers' }
  public listCarriers = [];
  public carrierStatusActive = Const.CarrierStatus.active
  constructor(protected activatedRoute: ActivatedRoute) {
    super(activatedRoute);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }
  protected getApiUrl(method: ApiMethod = ApiMethod.get): string {
    return Const.APIURI_DRIVERS;
  }

  get isMobile(): boolean {
    let mediaQuery = window.matchMedia('(max-width: 900px)'); 
    return mediaQuery.matches;
  }

  protected onGetDetailSuccess(data) {
    if (!data) return data;
    if (!data.isNotifyViaSMS) {
      data.isNotifyViaSMS = false;
    }
    return data;
  }
  
  onBtnEdit(){
    let phone = this.getChildFormInfoByKey('phone');
    phone.formControl.setValue(this.model.phone);
    super.onBtnEdit();
  }

}