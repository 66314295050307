import { Component } from "@angular/core";
import { Task as TaskModel } from '@wearewarp/types/data-model';
import { BaseFormDrawer } from "@app/drawers/base-form";

@Component({
  selector: '[job-orders]',
  templateUrl: './index.html',
  styleUrls: []
})
export class SeeAllOrder extends BaseFormDrawer {
  job: any;

  get routeLabel() {return this.job?.code ?? ''}
  get listData(): Array<TaskModel> {return this.job?.shipments ?? []}

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  goShipmentDetail(item: any) {
    this.onClose();
    let command = [this.routeAdminShipmentList, item.orderId, item.shipmentId]
    this.router.navigate(command);
  }
}