import { Component, Input } from "@angular/core";
import { Const } from "@const/Const";
import { DateUtil } from "@services/date-utils";
import { BaseComponent } from "@abstract/BaseComponent";

@Component({
  selector: '[comm-log-detail]',
  templateUrl: './detail.html',
  styleUrls: ['../../../dialogs/dialogs.scss', './detail.scss']
})

export class CommLog extends BaseComponent {
  private _logId = '';

  @Input() get logId() {
    return this._logId;
  }
  set logId(value) {
    this._logId = value;
    this.getDataLog();
  }

  public listChange = [];
  public isLoading: boolean = false;
  public isError: boolean = false;
  private type: string = '';
  private provider: string = '';
  private timezone: string = DateUtil.getLocalTimeZone();
  private statuses = Const.CommLogStatuses;

  makeMessage(item) {
    let { status, raw } = item;
    status = status.toLowerCase();
    status = this.mapStatus(status);

    const statuses = {
      pending: `Waiting to be processed`,
      sent: "Processed",
      failed: "Failed to send",
      success: "Successfully sent",
      delivered: `Received by ${raw?.email || raw?.delivery?.recipients?.[0]}`,
      // deferred: `The recipient temporarily rejects the message`,
      // dropped: `Dropped with reason: ${raw?.reason}`,
      // bounce: `The receiving server cannot or does not accept the message`,
      opened: `The recipient opened the email`,
      clicked: `The recipient clicked on the following <a target='_blank' rel='noopener' href='${raw?.url || raw?.click?.link}'>${raw?.url || raw?.click?.link}</a>`,
      // spam_report: `The recipient has marked the message as spam`,
      unsubscribed: `The recipient clicked the 'Opt out of all emails'`,
      undelivered: `The message has not arrived with ${raw?.email || raw?.delivery?.recipients?.[0]}`,
      invalid: `The recipient email is invalid`,
      unreachable: `The recipient's carrier is unreachable`,
      unknown: `Unknown status`
    }

    return statuses[status] || 'N/A';

    if(this.type === 'email') return statuses[status] || '';

    const smsStatuses = {
      queued: `The message has been queued`,
      sending: `The message is sending`,
      sent: `The message has been sent`,
      delivered: `Received by ${raw?.To}`,
      undelivered: `The message has not arrived with ${raw?.To}`,
      failed: `Request failed!`
    }
    return smsStatuses[status] || '';
  }

  mapStatus(status) {
    const _status = status.toLowerCase();
    switch (_status) {
      case "pending":
        return "pending";
      case "sent":
        return "sent";
      case "failed":
        return "failed";
      case "successful": // TWILIO success
      case "send": // SES suscess
        return "success";
      case "delivered": // TWILIO delivered
      case "delivery": // SES delivered
        return "delivered";
      case "open":
        return "opened";
      case "click":
        return "clicked";
      case "opted_out":
      case "unsubscribed":
        return "unsubscribed";
      case "undelivered":
        return "undelivered";
      case "invalid":
        return "invalid";
      case "carrier_unreachable":
      case "unreachable":
        return "unreachable";
      default:
        return "unknown";
    }

    if(!status) return '';
    status = status.toLowerCase();
    const statuses = {
      send: 'processed',
      renderingfailure: 'dropped',
      reject: 'deferred',
      delivery: 'delivered',
      complaint: 'spam_report',
      deliverydelay: 'delivered',
      subscription: 'unsubscribe'
    }

    return statuses[status] || status;
  }

  makeColor(status: string) {
    switch (this.mapStatus(status)) {
      case 'pending':
        return 'gray';
      case 'sent':
      case 'success':
        return 'green';
      case 'failed':
      case 'invalid':
        return 'red';
      case 'delivered':
      case 'opened':
      case 'clicked':
        return 'blue';
      case 'undelivered':
      case 'unreachable':
      case 'unknown':
        return 'orange';
      case 'unsubscribed':
        return 'black';
      default:
        return '';
    }
    if(!status) return '';
    status = status.toLowerCase();
    let rs = this.statuses.find(it => it.type === this.type && it.provider === this.provider && it.value === status);
    return rs ? rs.color : ''
  }

  getDataLog() {
    if(!this._logId) return this.listChange = [];
    this.isLoading = true;
    const url = `${Const.API_SERVICE_COMM_LOG}/${this._logId}`;
    this.api.GET(url).subscribe(
      resp => {
        this.type = resp.data.type.toLowerCase();
        this.provider = resp.data.provider;
        let deliverStatus = resp.data?.deliverStatus || [];
        deliverStatus = deliverStatus.sort(function (a, b) {
          let bDate = new Date(b.raw.time)
          let aDate = new Date(a.raw.time)
          return Number(bDate) - Number(aDate)
        });

        this.listChange = deliverStatus.map(it => {
          const { time } = it;
          const timeZoneShort = this.getTimezoneShort();
          const date = `${this.formatDate(time)} ${timeZoneShort ? `(${timeZoneShort})` : ''}`;
          return { message: this.makeMessage(it), color: this.makeColor(it.status), date }
        });
        this.isLoading = false;
        this.isError = false;
      }, err => {
        this.isLoading = false;
        this.isError = true;
        this.showErr(err);
      }
    );
  }

  formatDate(time: string) {
    return DateUtil.displayTimeWindow({ from: time, to: time }, {
      timezone: this.timezone,
      formatDateOnly: 'MM/DD/YY',
      format: "MM/DD/YY h:mm A",
    });
  }

  getTimezoneShort() {
    const localTimeZoneShort = DateUtil.timezoneStandardToUsShort(this.timezone);
    return localTimeZoneShort || '';
  }
}
