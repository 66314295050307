<div *ngIf="isLoading" class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
<div *ngIf="isError" class="nodata">Cannot fetch data, please try again later</div>

<div class="carrier-selection" *ngIf="!isLoading && !isError">
  <div *ngIf="onHold?.length" class="bottom15 warning">
    <span style="font-weight: 500;">Attention:</span> Some shipments on this route are currently <code>On Hold</code>:
    <nz-tag *ngFor="let s of onHold">{{ s }}</nz-tag>
  </div>

  <div *ngIf="jobHyperLink" class="bottom40 f18">
    Related route: <a [routerLink]="[jobHyperLink.hyperLinkUrl]" target="_blank">{{jobHyperLink.hyperLinkText}}</a>
  </div>

  <!-- Information -->
  <form nz-form class="form-detail" [formGroup]="formInput" nzLayout="vertical">
    <div nz-row [nzGutter]="8">
      <div nz-col [nzSpan]="16">
        <div class="box-with-title">
          <div class="box-title">Information</div>
          <div class="box-content">
            <div nz-row [nzGutter]="12">
              <div nz-col nzSpan="12">
                <nz-form-item>
                  <nz-form-label>{{getLabel("pickupAddress")}} <span *ngIf="isRequired('pickupAddress')"
                      class="label-mark-required"></span></nz-form-label>
                  <nz-form-control>
                    <input nz-input [placeholder]="getPlaceHolder('pickupAddress')" formControlName="pickupAddress"
                      [readonly]="true" />
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col nzSpan="12">
                <nz-form-item>
                  <nz-form-label>{{getLabel("dropoffAddress")}} <span *ngIf="isRequired('dropoffAddress')"
                      class="label-mark-required"></span></nz-form-label>
                  <nz-form-control>
                    <input nz-input [placeholder]="getPlaceHolder('dropoffAddress')" formControlName="dropoffAddress"
                      [readonly]="true" />
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col nzSpan="12">
                <nz-form-item>
                  <nz-form-label>{{getLabel("pickupDate")}} <span *ngIf="isRequired('pickupDate')"
                      class="label-mark-required"></span></nz-form-label>
                  <nz-form-control>
                    <nz-date-picker formControlName="pickupDate" class="full-w" nzFormat="yyyy-MM-dd HH:mm"
                      [nzDisabled]="true">
                    </nz-date-picker>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col nzSpan="12">
                <nz-form-item>
                  <nz-form-label>{{getLabel("dropoffDate")}} <span *ngIf="isRequired('dropoffDate')"
                      class="label-mark-required"></span></nz-form-label>
                  <nz-form-control>
                    <nz-date-picker formControlName="dropoffDate" class="full-w" nzFormat="yyyy-MM-dd HH:mm"
                      [nzDisabled]="true">
                    </nz-date-picker>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col nzSpan="12">
                <nz-form-item>
                  <nz-form-label>{{getLabel("serviceOptions")}} <span *ngIf="isRequired('serviceOptions')"
                      class="label-mark-required"></span></nz-form-label>
                  <nz-form-control>
                    <app-form-service-options formControlName="serviceOptions"></app-form-service-options>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col nzSpan="12">
                <div nz-row [nzGutter]="12">
                  <div nz-col nzSpan="12">
                    <nz-form-item>
                      <nz-form-label>{{getLabel("equipments")}} <span *ngIf="isRequired('equipments')"
                          class="label-mark-required"></span></nz-form-label>
                      Preferred:
                      <nz-form-control *ngIf="!data.vehicles?.length">
                        <app-form-equipments formControlName="equipments"></app-form-equipments>
                      </nz-form-control>
                      <div *ngIf="data.vehicles?.length">
                        <code *ngFor="let vehicle of data.vehicles">
                          {{ vehicle.name }}
                          <span *ngIf="vehicle.options?.length">
                            w/
                            <span *ngFor="let option of vehicle.options; let i=index" class="right5">
                              <normalized-string [str]="option"></normalized-string><ng-container *ngIf="i < (vehicle.options.length - 1)">,</ng-container>
                            </span>
                          </span>
                        </code>
                      </div>
                      <div style="margin-top: 10px;">
                        Optional: <i nz-icon nzType="info-circle" nzTheme="outline" nzTooltipPlacement="bottom"
                          nzTooltipOverlayClassName="tooltip-info" style="margin-left: 10px;" class="clickable"
                          nz-tooltip [nzTooltipTitle]="'The other carriers who have this equipment can also bid'"></i>
                      </div>
                      <code>{{ optionalEquipments(data.optionalEquipments) }}</code>
                    </nz-form-item>
                  </div>
                  <div nz-col nzSpan="12">
                    <div *ngIf="data?.job?.tempRange">
                      Temperature: {{data.job.tempRange}}
                    </div>
                    <div class="items">
                      <div *ngIf="isMarketPlaceLoad()" class="shipment-items">
                        Items: <br />
                        <ng-container *ngIf="isLoadingGhostShipment">
                          <nz-spin nzSimple></nz-spin>
                        </ng-container>
                        <ng-container *ngIf="!isLoadingGhostShipment">
                          {{totalShipmentsItems?.weight}}
                          <ng-container *ngIf="totalShipmentsItems?.units?.length">,
                            {{totalShipmentsItems.units}}</ng-container>
                          <div>
                            <span>{{getFirstCommodity()}}</span>
                            <ng-container *ngIf="totalShipmentsItems?.commodity.length > 2">
                              <span [nzTooltipTitle]="getAllCommodities()" nz-tooltip
                                style="margin-left: 5px;">+{{totalShipmentsItems.commodity.length - 2}}</span>
                            </ng-container>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div nz-col nzSpan="24" class="top10">
                <div class="group-button">
                  <button nz-button nzType="primary" (click)="onBtnPreviousRoute()">
                    <i nz-icon nzType="eye" nzTheme="outline"></i>
                    View Previous Routes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div nz-col [nzSpan]="8">
        <div class="box-with-title">
          <div class="box-title">Mode & Settings</div>
          <div class="box-content">
            <div nz-row [nzGutter]="12">
              <div nz-col nzSpan="24">
                <nz-form-item>
                  <nz-form-label>
                    {{getLabel("type")}}
                    <span *ngIf="isRequired('type')" class="label-mark-required"></span>
                  </nz-form-label>
                  <nz-form-control>
                    <nz-select formControlName="type">
                      <nz-option *ngFor="let option of carrierBIDType" [nzLabel]="getLabelType(option)"
                        [nzValue]="option">
                      </nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col nzSpan="24">
                <b>Settings: </b>
              </div>
              <div nz-col nzSpan="24">
                <nz-form-control>
                  <nz-form-control>
                    <div nz-checkbox formControlName="isAllowPlaceBid">
                      Allow the carrier place bid
                    </div>
                  </nz-form-control>
                </nz-form-control>
              </div>
              <div nz-col nzSpan="24">
                <nz-form-control>
                  <nz-form-control>
                    <div nz-checkbox formControlName="isShowBasePrice">
                      Show WARP Offer
                    </div>
                  </nz-form-control>
                </nz-form-control>
              </div>
              <div nz-col nzSpan="24">
                <nz-form-label>
                  WARP Offer (Base Price)
                  <span *ngIf="isRequired('basePrice')" class="label-mark-required"></span>
                </nz-form-label>
                <nz-form-control>
                  <input nz-input [placeholder]="getPlaceHolder('basePrice')"
                    (input)="onInputChanged($event, 'basePrice')" formControlName="basePrice" />
                </nz-form-control>
              </div>
              <div nz-col nzSpan="24">
                <nz-form-control>
                  <nz-form-control>
                    <div nz-checkbox formControlName="isShowOnBidBoard">
                      {{getLabel("isShowOnBidBoard")}}
                      <span style="margin-left: 10px;" nz-icon nzType="info-circle" nzTheme="outline" nz-tooltip
                        nzTooltipTitle="Allow all the carries, including unmatched, to see the load on their Load Board">
                      </span>
                    </div>
                  </nz-form-control>
                </nz-form-control>
              </div>
              <div nz-col nzSpan="24">
                <nz-form-control>
                  <nz-form-control>
                    <div nz-checkbox formControlName="isRequireCarrierAcceptLoadTender">
                      {{getLabel("isRequireCarrierAcceptLoadTender")}}
                    </div>
                  </nz-form-control>
                </nz-form-control>
              </div>
              <div nz-col nzSpan="24" style="margin-top: 24px;">
                <div class="group-button" style="float: right">
                  <button nz-button nzType="primary" (click)="onBtnEdit()">
                    <i nz-icon nzType="edit" nzTheme="outline"></i>
                    Edit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

  <!-- Mode & Settings -->
  <div *ngIf="acceptedPrice" class="box-with-title" style="padding: 20px;">
    <div nz-row [nzGutter]="12">
      <div nz-col nzSpan="8">
        <div>Accepted Price:</div>
        <h4 nz-typography>{{formatMoney(acceptedPrice)}}</h4>
        <span><nz-tag *ngIf="acceptedCarrier?.isEarliestBid" [nzColor]="'blue'">Earliest Bid</nz-tag></span>
        <span><nz-tag *ngIf="acceptedCarrier?.isLowestPrice" [nzColor]="'blue'">Lowest Price</nz-tag></span>
        <span><nz-tag *ngIf="acceptedCarrier?.isBestExperience" [nzColor]="'blue'">Best Experience</nz-tag></span>
        <div style="margin-top: 10px;">{{acceptedCarrier?.basicInfo?.name}}</div>
      </div>
      <div nz-col nzSpan="8">
        <ng-container *ngIf="compareWithLowestPrice" [ngTemplateOutlet]="statisticTemplate"
          [ngTemplateOutletContext]="{data: compareWithLowestPrice}">
        </ng-container>

        <!-- bug: wrong market price => wrong compare (FIX ME) -->
        <!-- <ng-container
          *ngIf="compareWithMarketPrice"
          [ngTemplateOutlet]="statisticTemplate"
          [ngTemplateOutletContext]="{data: compareWithMarketPrice}">
        </ng-container> -->

        <ng-container *ngIf="compareWithOfferPrice" [ngTemplateOutlet]="statisticTemplate"
          [ngTemplateOutletContext]="{data: compareWithOfferPrice}">
        </ng-container>

        <ng-template #statisticTemplate let-data='data'>
          <div style="margin-bottom: 10px;">
            <nz-tag [nzColor]="getCompareBidPriceColor(data?.status)">
              <span nz-icon [nzType]="getCompareBidPriceIcon(data?.status)" nzTheme="outline"></span>
              <span style="margin-right: 5px; margin-left: 2px;" *ngIf="data?.percent">{{data?.percent}}</span>
              <span>{{data?.text}}</span>
            </nz-tag>
          </div>
        </ng-template>
      </div>
    </div>
  </div>

  <!-- Carriers & DAT -->
  <div class="box-with-title" style="margin-top: 30px;">
    <div class="box-title">Carriers <span *ngIf="isRequired('carrierIds')" class="label-mark-required"></span></div>
    <div class="box-content">
      <div class="group-box-action">
        <div class="box-action" *ngIf="!isAdminReadOnlyRole">
          <button nz-button (click)="openAddCarrierModal()" nzType="default">
            <span nz-icon nzType="plus" nzTheme="outline"></span>
            Add carrier
          </button>
          <button nz-button (click)="openAddCarrierPoolModal()" nzType="default">
            <span nz-icon nzType="plus-circle" nzTheme="outline"></span>
            Add Pools
          </button>
          <button nz-button (click)="onAddCarriersByCoverage()" nzType="default">
            <span nz-icon nzType="plus-circle" nzTheme="outline"></span>
            Add Carriers
          </button>
          <button nz-button (click)="onClickPoolCoverage()" nzType="default">
            <span nz-icon nzType="plus-circle" nzTheme="outline"></span>
            Add Pools By Coverage Area
          </button>

          <button nz-button (click)="confirmRemoveCarrier()" nzType="default" nzDanger
            [disabled]="isNoChecked() || shouldDisableRemoveButton()">
            <span nz-icon nzType="delete" nzTheme="outline"></span>
            Remove selected
          </button>
        </div>
        <div class="box-action" *ngIf="!isAdminReadOnlyRole">
          <!-- <dat-post-button-v2 *ngIf="carrierBidId" [carrierBidId]="carrierBidId" [jobId]="data.jobId"></dat-post-button-v2> -->
          <button *ngIf="shouldShowBtnDat" nz-button (click)="onBtnDatLoad()" style="margin-left: 10px;"
            nzType="primary">
            <i nz-icon nzType="send" nzTheme="outline"></i>
            {{datPostExist ? "View DAT post" : "Post to DAT"}}
          </button>
          <button nz-button (click)="onBtnSendMessage()" style="margin-left: 10px;" nzType="primary"
            [disabled]="isNoChecked()">
            <i nz-icon nzType="send" nzTheme="outline"></i>
            Send email & sms to carriers
          </button>
        </div>
      </div>
      <nz-table #nzTable nzBordered="true" [nzData]="listData" [nzLoading]="isLoading" [nzHideOnSinglePage]="true"
        [nzLoadingIndicator]="tplLoading" [nzNoResult]="tplNoData" [nzSize]="'default'">
        <ng-template #tplNoData>
          <div *ngIf="!isLoading" class="nodata"><i nz-icon nzType="search" nzTheme="outline"></i>No data</div>
        </ng-template>
        <ng-template #tplLoading>
          <div class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
        </ng-template>
        <thead>
          <tr>
            <th nzLeft nzColumnKey="checkbox" *ngIf="!isAdminReadOnlyRole" nzWidth="50px">
              <label nz-checkbox (nzCheckedChange)="onCheckedChange($event, 'all')" [nzChecked]="isCheckedAll()"
                [nzIndeterminate]="isIndeterminate()">
              </label>
            </th>
            <th nzCustomFilter nzLeft nzColumnKey="name">
              <div class="flex">
                Carrier Name
                <!-- <nz-filter-trigger [(nzVisible)]="isVisibleFilterPool" [nzActive]="isActiveFilterPool" [nzDropdownMenu]="menu">
                  <span nz-icon nzType="filter" nzTheme="fill"></span>
                </nz-filter-trigger>
                <nz-dropdown-menu #menu="nzDropdownMenu">
                  <div class="ant-table-filter-dropdown">
                    <div class="filter-box">
                      <div class="filter-title">
                        Filter by Carrier Pool
                      </div>
                      <nz-divider></nz-divider>
                      <ng-container *ngIf="shouldShowFilterPool()">
                        <nz-checkbox-group class="filter-box" [(ngModel)]="listPool" (ngModelChange)="onChangeFilterPool($event)"></nz-checkbox-group>
                        <div class="filter-action">
                          <button nz-button nzType="default" (click)="onBtnResetFilterPool()">Reset</button>
                          <button nz-button nzType="primary" (click)="onBtnFilterPool()">Ok</button>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="!shouldShowFilterPool()">
                        <label><span>No pool added</span></label>
                      </ng-container>
                    </div>
                  </div>
                </nz-dropdown-menu> -->
              </div>
              <input (input)="onSearch($event.target.value, 'name')" type="text" class="input-search" nz-input
                placeholder="Search by Carrier Name" nzSize="default" />
            </th>
            <th nzCustomFilter nzLeft nzColumnKey="poolName">
              Dedicated Pool
              <input (input)="onSearch($event.target.value, 'poolName')" type="text" class="input-search" nz-input
                placeholder="Search by Carrier Pool" nzSize="default" />
            </th>
            <th nzLeft nzColumnKey="contacts">
              Carrier Contact
              <input (input)="onSearch($event.target.value, 'contacts')" type="text" class="input-search" nz-input
                placeholder="Search by Carrier Contact" nzSize="default" />
            </th>
            <th nzColumnKey="completedRoute">Completed Route</th>
            <th nzColumnKey="status">Type</th>
            <th nzColumnKey="answer">Last Sent</th>
            <th nzColumnKey="answer">Answer</th>
            <th nzColumnKey="price" [nzSortOrder]="sortPriceDirection" [nzSortDirections]="['ascend', 'descend', null]"
              [nzSortFn]='sortPriceCarrier' (nzSortOrderChange)="changeSort($event)">Price</th>
            <th nzColumnKey="action" *ngIf="data.jobId">Action</th>
            <th nzRight nzColumnKey="note">Note</th>
          </tr>
        </thead>
        <tbody>

          <ng-container *ngFor="let carrier of nzTable.data">
            <ng-container *ngFor="let item of mapOfExpandedData[carrier.key]">
              <tr *ngIf="(item.parent && item.parent.expand) || !item.parent">
                <td nzLeft *ngIf="!isAdminReadOnlyRole">
                  <label *ngIf="!isGroupByPool(item)" nz-checkbox [(ngModel)]="item['checked']"
                    (nzCheckedChange)="onItemCheckedChange($event,item)">
                  </label>
                </td>
                <td nzLeft [nzIndentSize]="item.level*0" [nzShowExpand]="false" [(nzExpand)]="item.expand"
                  (nzExpandChange)="onExpandChange(mapOfExpandedData[carrier.key], item, $event)"
                  [style]="item.level && item.level > 0 ? 'background-color:rgb(218, 218, 215);': ''">
                  <div *ngIf="isGroupByPool(item)" (click)="toggleChildren(carrier, item)" style="cursor: pointer;">
                    <div style="font-weight: 500;">
                      [Dedicated Pool]
                      <span>{{item.poolName}} ({{item?.children?.length || 0}})</span>
                      <i nz-icon [nzType]="item.expand ? 'up' : 'down'" nzTheme="outline"
                        *ngIf="(item.children || []).length>0" style="margin-left: 10px"></i>
                    </div>
                  </div>
                  <div style="margin-bottom: 10px;">
                    <nz-tag *ngIf="item.isEarliestBid" nzColor="blue">Earliest Bid</nz-tag>
                    <nz-tag *ngIf="item.isLowestPrice" nzColor="blue">Lowest Price</nz-tag>
                    <nz-tag *ngIf="item?.isBestExperience" [nzColor]="'blue'">Best Experience</nz-tag>
                  </div>
                  <a *ngIf="!isGroupByPool(item)" class="inherit" target="_blank"
                    [routerLink]="[routeAdminCarrierList, item.carrierId]">{{item.name}}</a>
                </td>
                <td nzLeft>
                  <ng-container *ngIf="item.isDedicatedPool">
                    <nz-ribbon nzText="Dedicated">
                      <a [routerLink]="[routeAdminPools, item.poolId]" target="_blank">{{item.poolName}}</a> <br />
                      Base Rate: <b>{{ item?.basePrice ? $asMoney(item.basePrice) : '-' }}</b>
                    </nz-ribbon>
                  </ng-container>
                  <ng-container *ngIf="!item.isDedicatedPool">-</ng-container>
                </td>
                <td nzLeft>
                  <ng-container *ngIf="!isGroupByPool(item)">
                    {{item.contacts?.[0]?.contactName}}<br />
                    <p class="small-text">
                      <contact-info owner="{{item.contacts?.[0]?.contactPhone}}" type="phone"
                        [scopes]="['carrier_bid']"></contact-info>
                      <contact-info owner="{{item.contacts?.[0]?.contactEmail}}" type="email"
                        [scopes]="['carrier_bid']"></contact-info>
                    </p>
                  </ng-container>
                </td>
                <td>
                  <ng-container *ngIf="!isGroupByPool(item)">
                    <div *ngIf="item.countOfJob == 0">
                      -
                    </div>
                    <div *ngIf="item.countOfJob > 0">
                      Completed:
                      <span nz-tooltip nzTooltipTitle="Count of Routes completed">{{ item.countOfJob }}</span> /
                      <span nz-tooltip nzTooltipTitle="Count of Routes completed for the specific lane">{{
                        item.countLane
                        }}</span>
                      <p class="small-text">
                        <span>Last date: {{ item.lastDate ? $formatDate(item.lastDate) : '-' }}</span>
                        <ng-container *ngIf="item.countLane">
                          <br />
                          <span>Last cost: {{ item.lastCost ? $asMoney(item.lastCost) : '-' }}</span>
                        </ng-container>
                      </p>
                    </div>
                  </ng-container>
                </td>
                <td>
                  <ng-container *ngIf="!isGroupByPool(item)">
                    <ng-container *ngIf="item.status != 1">
                      <nz-tag nzColor="warning">{{$asCarrierStatusText(item.status)}}</nz-tag>
                    </ng-container>
                    <ng-container *ngIf="item.status == 1">
                      <nz-tag nzColor="success">Active</nz-tag>
                    </ng-container>
                  </ng-container>
                </td>
                <td>
                  <ng-container *ngIf="!isGroupByPool(item)">
                    <ng-container *ngIf="item.lastSent">
                      {{$formatDate(item.lastSent?.when)}} <br />
                      By {{item.sender?.name}}<br />
                      <span class="status" [ngClass]="$asSentStatusColor(item.lastSent.status)">{{
                        $asSentStatusText(item.lastSent.status) }}</span>
                      <span *ngIf="$shouldShowHistory(item.lastSent)" (click)="onBtnHistoryStatus(item)" nz-icon
                        nzType="history" nzTheme="outline" style="cursor: pointer;"></span>
                    </ng-container>
                    <ng-container *ngIf="!item.lastSent">
                      Never
                    </ng-container>
                  </ng-container>
                </td>
                <td>
                  <ng-container *ngIf="!isGroupByPool(item)">
                    <div class="flex flex-space-between">
                      <div>
                        <ng-container *ngIf="item.state == 0 || item.state == undefined">
                          <ng-container *ngIf="item.lastSent">
                            <nz-tag nzColor="warning">Waiting for a response</nz-tag>
                          </ng-container>
                          <ng-container *ngIf="!item.lastSent">
                            <nz-tag nzColor="default">Not Sent</nz-tag>
                          </ng-container>
                        </ng-container>
                        <ng-container *ngIf="item.state == 1">
                          <nz-tag nzColor="error">Refused</nz-tag>
                        </ng-container>
                        <ng-container *ngIf="item.state == 2">
                          <nz-tag nzColor="success">Accepted</nz-tag>
                        </ng-container>
                        <ng-container *ngIf="item.state == 3">
                          <nz-tag nzColor="success">Placed Bid</nz-tag>
                        </ng-container>
                        <p class="small-text" style="margin-top: 5px;" *ngIf="item.when">when:
                          {{$formatDate(item.when)}}</p>
                      </div>
                      <div *ngIf="!isAdminReadOnlyRole">
                        <button nz-button (click)="onBtnEditItem(item)" nzSize="small">
                          <i nz-icon nzType="edit" nzTheme="outline"></i>
                        </button>
                      </div>
                    </div>
                  </ng-container>
                </td>
                <td nzAlign="right">
                  <ng-container *ngIf="!isGroupByPool(item)">
                    {{ item.price? $asMoney(item.price): '-'}}
                  </ng-container>
                </td>
                <td class="border-right-none" *ngIf="data.jobId">
                  <div class="flex assign-item" *ngIf="!isAdminReadOnlyRole">
                    <div
                      *ngIf="item?.carrierId && item?.carrierId != data?.job?.assignedCarrier?.carrierId && [2, 3].includes(item.state)">
                      <div *ngIf="item.status == 1 && (!isSourceMarketPlace || data.populated)">
                        <img class="svg-icon truck1 right10" alt="">
                        <a (click)="onBtnAcceptBid(item)" style="margin-right: 10px;">Accept this bid</a>
                      </div>
                      <div *ngIf="item.status == 1 && isSourceMarketPlace && !data.populated">
                        <img class="svg-icon truck1 right10" alt="" nz-tooltip
                          nzTooltipTitle="This carrier cannot be assigned because relate to Marketplace Order">
                      </div>
                      <div *ngIf="item.status !== 1">
                        <a nz-tooltip="This carrier cannot be assigned because it is not in Active state.">-</a>
                      </div>
                    </div>
                    <div
                      *ngIf="item?.carrierId && item?.carrierId == data?.job?.assignedCarrier?.carrierId && [2, 3].includes(item.state)">
                      <span nz-typography nzType="success">
                        <span nz-icon nzType="check" nzTheme="outline"></span> Accepted
                        <p class="small-text">
                          <span>by: {{ getFullName(data?.job?.assignedCarrier?.update?.byUser) || "N/A" }}</span>
                          <br />
                          <span>when: {{ $formatDate(data?.job?.assignedCarrier?.update?.when) }}</span>
                        </p>
                      </span>
                    </div>
                  </div>
                </td>
                <td nzRight>
                  <div *ngIf="!isGroupByPool(item)" class="flex flex-space-between">
                    <div>
                      <p *ngIf="item.note" nz-typography nzEllipsis nzExpandable [nzEllipsisRows]="2" class="note-text">
                        {{item.note}}
                      </p>
                    </div>
                    <div *ngIf="!isAdminReadOnlyRole">
                      <button nz-button (click)="onBtnEditNote(item)" nzSize="small">
                        <i nz-icon nzType="edit" nzTheme="outline"></i>
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            </ng-container>
          </ng-container>
        </tbody>
      </nz-table>

    </div>
  </div>
  <div *ngIf="arrBidFromPublic.length" class="box-with-title" style="margin-top: 50px">
    <div class="box-title">Bid From Public</div>
    <div class="box-content">
      <nz-table #nzTablePublic [nzData]="arrBidFromPublic" nzBordered="true" [nzHideOnSinglePage]="true"
        [nzSize]="'default'">
        <thead>
          <tr>
            <th>MC/DOT</th>
            <th>MC/DOT Information</th>
            <th>Answer</th>
            <th>Price</th>
            <th>WARP Carrier Name</th>
            <th>Type</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let item of nzTablePublic.data">
            <tr>
              <td>
                <div *ngIf="item.dot">DOT: {{item.dot}}</div>
                <div *ngIf="item.mc">MC: {{item.mc}}</div>
              </td>
              <td>
                <ng-container *ngIf="!item.mcDotInfo">-</ng-container>
                <ng-container *ngIf="item.mcDotInfo">
                  <div>Company Name: {{item.mcDotInfo.companyName || 'N/A'}}</div>
                  <div>Address: {{item.mcDotInfo.addressText || 'N/A'}}</div>
                  <div>First Name: {{item.mcDotInfo.firstName || 'N/A'}}</div>
                  <div>Last Name: {{item.mcDotInfo.lastName || 'N/A'}}</div>
                  <div>Phone Number: {{item.mcDotInfo.phone || item.mcDotInfo.originPhone || 'N/A'}}</div>
                  <div>Email: {{item.mcDotInfo.email || item.mcDotInfo.originEmail || 'N/A'}}</div>
                </ng-container>
              </td>
              <td>
                <ng-container *ngIf="item.state == 1">
                  <nz-tag nzColor="error">Refused</nz-tag>
                </ng-container>
                <ng-container *ngIf="item.state == 2">
                  <nz-tag nzColor="success">Accepted</nz-tag>
                </ng-container>
                <ng-container *ngIf="item.state == 3">
                  <nz-tag nzColor="success">Placed Bid</nz-tag>
                </ng-container>
                <p class="small-text" style="margin-top: 5px;" *ngIf="item.insert?.when">when:
                  {{$formatBidPublicDate(item.insert.when)}}</p>
              </td>
              <td>
                {{ item.price? $asMoney(item.price): '-'}}
              </td>
              <td>
                {{ item.carrierInfo?.name || '-' }}
              </td>
              <td>
                <ng-container *ngIf="!item.carrierInfo">-</ng-container>
                <ng-container *ngIf="item.carrierInfo && item.carrierInfo.status != 1">
                  <nz-tag nzColor="warning">{{$asCarrierStatusText(item.carrierInfo.status)}}</nz-tag>
                </ng-container>
                <ng-container *ngIf="item.carrierInfo && item.carrierInfo.status == 1">
                  <nz-tag nzColor="success">Active</nz-tag>
                </ng-container>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </nz-table>
    </div>
  </div>
</div>

<div class="footer-btn" *ngIf="!isAdminReadOnlyRole && !isLoading && !isError">
  <button *ngIf="shouldShowHistory" nz-button (click)="goToHistory(model, routeAdminCarrierBidList)">
    <i nz-icon nzType="history" nzTheme="outline"></i>
    View History
  </button>
</div>