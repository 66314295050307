import { Component, Input, ElementRef, ViewChild } from '@angular/core';
import mapboxgl from 'mapbox-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { Subscription } from 'rxjs';
import { CircleMode, DragCircleMode, DirectMode, SimpleSelectMode  } from 'mapbox-gl-draw-circle';
import { BaseDialog } from '@dialogs/base-dlg';
import * as turf from "@turf/turf";
import * as statesJsonModule from './data/states.json';
import { MasterData } from '@services/master.data';
import { ulid } from 'ulid';
import { Const } from '@const/Const';
const statesJson = statesJsonModule['default'];
import polyline from '@mapbox/polyline';
import { MapBidService } from '@app/admin/carrier-bids/services/map';
import { Utils } from "@services/utils";
import { Carrier } from '@wearewarp/types/data-model';

@Component({
  selector: '[select-carrier-by-service-area]',
  templateUrl: './index.html',
  styleUrls: ['../../../../../app.scss', '../../../../../dialogs/dialogs.scss', './index.less']
})
export class SelectCarrierByServiceArea extends BaseDialog {
  _model = null;
  @Input() set model(value) {
    this.featureList = [];
    this._model = value
    this.mapService = new MapBidService()
    this.mapService.setBid(value)
  }
  mapService: MapBidService
  get model() {return this._model}
  map = null;
  popup = null;
  draw = null;
  isSelectAll: boolean = false;
  featureList: Array<DataServiceArea> = [];
  matchedAreas: Array<DataServiceArea> = [];
  matchedCarriers: Array<Carrier> = [];
  beforeSelectAllFeatureList: Array<DataServiceArea> = [];
  featureCollectionName = [];
  clickAreaId = null;
  syncLoading = false
  selectBtnValues = {
    state: 'state',
    metro: 'metro',
    draw: 'draw',
    radius: 'radius',
    point: 'point',
  }
  buttonSelect = this.selectBtnValues.state;

  defaultLayerId = {
    counties: 'counties',
    states: 'states',
    addSelected: 'addSelected',
    canadaMetro: 'canadaMetro'
  };
  routeData;

  public onSave: (data) => void = () => {};
  public onDelete: () => void = () => {};

  @ViewChild('carrierCoverageArea') carrierCoverageArea: ElementRef<HTMLElement>;

  constructor() {
    super();
  }
  ngOnInit(): void {
    super.ngOnInit();
    this.getRouteData()
    setTimeout(() => {
      this.setupMap();
    }, 500);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  public onBtnCancel() {
    this.closeDialog();
  }

  onDeletedSelectedItem(value) {
    this.isSelectAll = false;
    this.featureList = this.featureList.filter(item => !(item.data.id == value.data.id && item.data.source == value.data.source));
    this.updateSelectedArea();
  }

  isSelectButon(value) {
    return value == this.buttonSelect;
  }
  onSelectBtn(value) {
    if (this.popup) this.popup.remove();
    switch(this.buttonSelect) {
      case this.selectBtnValues.point: 
        this.map.removeControl(this.draw);
        break;
      case this.selectBtnValues.state: 
        this.removeState();
        break;
      case this.selectBtnValues.metro: 
        this.removeMetro();
        break;
      case this.selectBtnValues.radius:
        this.map.removeControl(this.draw);
        break;
    }
    switch(value) {
      case this.selectBtnValues.point: 
        this.map.addControl(this.draw);
        this.draw.changeMode('draw_polygon');
        break;
      case this.selectBtnValues.state: 
        this.addState();
        break;
      case this.selectBtnValues.metro: 
        this.addUSAMetro();
        this.addCanadaMetro()
        break;
      case this.selectBtnValues.radius:
        this.map.addControl(this.draw);
        this.draw.changeMode('draw_circle', { initialRadiusInKm: 200 });
        break;
      case this.selectBtnValues.draw:
        break;
    }
    this.buttonSelect = value;
  }

  private processSelectedAreaItem() {
    const val = [];
    this.featureList.forEach(item => {
      val.push(item.data);
    })
    return val;
  }

  private addSelectedArea() {
    const data = {
      type: 'FeatureCollection',
      features: this.processSelectedAreaItem(),
    };
    this.map.addSource(this.defaultLayerId.addSelected, { type: 'geojson', data});
    this.map.addLayer({
      'id': this.defaultLayerId.addSelected,
      'type': 'fill',
      'source': this.defaultLayerId.addSelected,
      'layout': {},
      'paint': {
        'fill-color': [
          'case',
          ['boolean', ['feature-state', 'click'], false],
          'rgba(218, 153, 22, 0.01)',
          'rgba(24, 2, 208, 0.01)',
        ]
      }
    });
    this.map.addLayer({
      'id': 'addSelected-border',
      'type': 'line',
      'source': this.defaultLayerId.addSelected,
      'layout': {},
      'paint': {
        'line-color': [
          'case',
          ['boolean', ['feature-state', 'click'], false],
          'rgba(218, 153, 22, 1)',
          '#1802D0',
        ] ,
        'line-width': 2
      }
    });
    this.map.on('click', this.defaultLayerId.addSelected, this.onClickAreaSelected);
    this.map.on('mouseenter', this.defaultLayerId.addSelected, () => {
      this.map.getCanvas().style.cursor = 'pointer';
    });
    this.map.on('mouseleave', this.defaultLayerId.addSelected, () => {
      this.map.getCanvas().style.cursor = '';
    });
  }

  get itemHightlight() {
    let val = null;
    if (this.clickAreaId) {
      val = this.featureList.find(item => item.data.id == this.clickAreaId);
    }
    return val;
  }

  processHightlight(feature, lngLat) {
    if (!lngLat) {
      const point = turf.centerOfMass(feature);
      lngLat = point?.geometry?.coordinates;
    }
    const id = feature.id || feature.properties.id;
    const item = this.featureList.find(item => {
      return item.data.properties.id === id;
    });
    const description = item?.name;
    if (this.popup) {
      this.popup.remove();
    }
    this.popup = new mapboxgl.Popup()
    .setLngLat(lngLat)
    .setHTML(`<div style="margin-top: 5px; display: flex;">
      <div style="font-size:13px; margin-right: 30px">${description}</div>
      <img id="btn-delete" class="delete right10" style="width: 16px; height: 16px;cursor: pointer;" alt="">
    </div>`)
    .addTo(this.map);
    if (this.clickAreaId) {
      this.map.setFeatureState(
        { source: this.defaultLayerId.addSelected, id: this.clickAreaId },
        { click: false }
      );
    }
    this.clickAreaId = feature.id || feature.properties.id;
    this.map.setFeatureState(
      { source: this.defaultLayerId.addSelected, id: this.clickAreaId },
      { click: true }
    );
    document.getElementById("btn-delete").addEventListener("click", () =>{
      this.onDeletedSelectedItem(item);
    }); 
  }

  onSelectHightlight(item) {
    this.processHightlight(item.data, item.lngLat);
  }

  onClickAreaSelected = (e) => {
    if (this.buttonSelect == this.selectBtnValues.state || this.buttonSelect == this.selectBtnValues.metro) {
      e.clickOnLayer = true;
      const feature = e.features[0];
      this.processHightlight(feature, e.lngLat);
    }
  }

  private updateSelectedArea() {
    const data = {
      type: 'FeatureCollection',
      features: this.processSelectedAreaItem(),
    };
    this.map.getSource(this.defaultLayerId.addSelected).setData(data);
    this.onHideAreaHightlighed();
  }

  private addState() {
    if (!this.map.getSource(this.defaultLayerId.states)) {
      this.map.addSource(this.defaultLayerId.states, {
        'type': 'geojson',
        'data': statesJson
      });

      this.map.addLayer({
        'id': 'state-fills',
        'type': 'fill',
        'source': this.defaultLayerId.states,
        'layout': {},
        'paint': {
          'fill-color': 'rgba(0,0,0,0)',
        }
      });
         
      this.map.addLayer({
        'id': 'state-borders',
        'type': 'line',
        'source': this.defaultLayerId.states,
        'layout': {},
        'paint': {
          'line-color': 'rgba(0,0,0,0.1)',
          'line-width': 1
        }
      });
  
      this.map.on('click', 'state-fills', this.onAddStateItem);
    }
  }
  onAddStateItem = (e) => {
    if (e.clickOnLayer) {
      return;
    }
    if (e?.features?.length > 0) {
      const stores = statesJson?.features;
      const feature = stores.find(store => store.id == e.features[0].id);
      if (!this.featureList.find(item => item.source === this.defaultLayerId.states && item.data.properties.id === feature.id && item.type == this.selectBtnValues.state)){ 
        this.featureList.push({ 
          type: this.selectBtnValues.state, 
          name: feature.properties.STATE_NAME,
          lngLat: e.lngLat,
          source: this.defaultLayerId.states,
          data: {
            type: 'Feature',
            geometry: feature.geometry,
            properties: { ...feature.properties, source: this.defaultLayerId.states, id: feature.id, type: this.selectBtnValues.state},
            id: ulid(),
          }
        });
      }
    }
    this.updateSelectedArea();
  }

  private onRemoveLayer(id) {
    if (this.map.getLayer(id)) {
      this.map.removeLayer(id);
    }
  }
  private onRemoveSource(id) {
    if (this.map.getSource(id)) {
      this.map.removeSource(id);
    }
  }

  private removeState() {
    if (this.map.getLayer('state-fills')) {
      this.map.off('click', 'state-fills', this.onAddStateItem);
    }
    this.onRemoveLayer('state-fills');
    this.onRemoveLayer('state-borders');
    this.onRemoveSource(this.defaultLayerId.states);
  }

  private addUSAMetro() {
    if (this.map) {
      this.map.addSource(this.defaultLayerId.counties, {
        type: 'vector',
        url: 'mapbox://mapbox.82pkq93d'
      });
      this.map.addLayer({
        id: this.defaultLayerId.counties,
        type: 'fill',
        source: this.defaultLayerId.counties,
        'source-layer': 'original',
        paint: {
          'fill-outline-color': 'rgba(0,0,0,0.1)',
          'fill-color': 'rgba(0,0,0,0)'
        }
      },'building');
         
      this.map.on('click', this.defaultLayerId.counties, this.onAddMetroItem);
    }
    
  }

  private addCanadaMetro() {
    if (this.map) {
      if (!this.map.getSource(this.defaultLayerId.canadaMetro)) {
        this.map.addSource(this.defaultLayerId.canadaMetro, {
          type: 'vector',
          url: 'mapbox://h2cbkhn.80jldafd'
        });
        this.map.addLayer({
          id: this.defaultLayerId.canadaMetro,
          type: 'fill',
          source: this.defaultLayerId.canadaMetro,
          'source-layer': 'canada-cities-dnxy9s',
          paint: {
            'fill-outline-color': 'rgba(0,0,0,0.1)',
            'fill-color': 'rgba(0,0,0,0)'
          }
        },'building');
           
        this.map.on('click', this.defaultLayerId.canadaMetro, this.onAddMetroItem);
      }
    }
    
  }
  private removeMetro() {
    if (this.map.getLayer(this.defaultLayerId.counties)) {
      this.map.off('click', this.defaultLayerId.counties, this.onAddMetroItem);
    }
    this.onRemoveLayer(this.defaultLayerId.counties);
    this.onRemoveSource(this.defaultLayerId.counties);

    if (this.map.getLayer(this.defaultLayerId.canadaMetro)) {
      this.map.off('click', this.defaultLayerId.canadaMetro, this.onAddMetroItem);
    }
    this.onRemoveLayer(this.defaultLayerId.canadaMetro);
    this.onRemoveSource(this.defaultLayerId.canadaMetro);
  }
  onAddMetroItem = (e) => {
    if (e.clickOnLayer) {
      return;
    }
    if (e.features.length > 0) {
      const feature = e.features[0];
      if (feature?.source === this.defaultLayerId.counties) {
        const findIndex = this.featureList.findIndex(item => item.source == this.defaultLayerId.counties && item.data.properties.FIPS === feature.properties.FIPS && item.type == this.selectBtnValues.metro);
        const data = { 
          type: this.selectBtnValues.metro, 
          name: feature.properties.COUNTY ? feature.properties.COUNTY.replace('County', '').trim() : '', 
          lngLat: e.lngLat,
          source: this.defaultLayerId.counties,
          data: {
            type: 'Feature',
            geometry: feature.geometry,
            properties: { ...feature.properties, source: this.defaultLayerId.counties, id: feature.id, type: this.selectBtnValues.metro, SHORT_COUNTRY: 'USA'},
            id: ulid(),
          }
        }
        if (findIndex < 0){ 
          this.featureList.push(data);
        } else {
          this.featureList[findIndex] = data;
        }
      }
      if (feature?.source === this.defaultLayerId.canadaMetro) {
        const findIndex = this.featureList.findIndex(item => item.source == this.defaultLayerId.canadaMetro 
          && item.data.properties.id == feature.properties.CCSUID 
          && item.type == this.selectBtnValues.metro
        );
        const data = { 
          type: this.selectBtnValues.metro, 
          name: feature.properties.CCSNAME, 
          lngLat: e.lngLat,
          source: this.defaultLayerId.canadaMetro,
          data: {
            type: 'Feature',
            geometry: feature.geometry,
            properties: { ...feature.properties, source: this.defaultLayerId.canadaMetro, id: feature.properties?.CCSUID, type: this.selectBtnValues.metro, SHORT_COUNTRY: 'CA'},
            id: ulid(),
          }
        }
        if (findIndex < 0){ 
          this.featureList.push(data);
        } else {
          this.featureList[findIndex] = data;
        }
      }
      
     
    }
    this.updateSelectedArea();
  }

  // Dùng để khai báo Radius và Point
  private drawInit() {
    this.draw = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        polygon: true,
        trash: true,
      },
      defaultMode: 'draw_circle',
      userProperties: true,
      modes: {
        ...MapboxDraw.modes,
        draw_circle  : CircleMode,
        drag_circle  : DragCircleMode,
        direct_select: DirectMode,
        simple_select: SimpleSelectMode,
      }
    });
    this.map.on('draw.create', (e) => {
      if (this.buttonSelect == this.selectBtnValues.point || this.buttonSelect == this.selectBtnValues.radius) {
        setTimeout(() => {
          this.addPopupAddArea(e);
        }, 100);
        this.updateArea(e);
      }
    });
    this.map.on('draw.delete', (e) => this.updateArea(e));
    this.map.on('draw.update', (e) => {
      if (this.buttonSelect == this.selectBtnValues.point || this.buttonSelect == this.selectBtnValues.radius) {
        this.addPopupAddArea(e);
      }
      this.updateArea(e);
    });
    this.map.on('draw.actionable', (e) => this.actionableArea(e));
    this.map.on('draw.combine', (e) => this.updateArea(e));
    this.map.on('draw.modechange', (e) => this.updateArea(e));
    this.map.on('draw.render', (e) => this.updateArea(e));
    this.map.on('draw.selectionchange', (e) => this.updateArea(e));
    this.map.on('draw.uncombine', (e) => this.updateArea(e));
  }

  private setupMap() {
    const mapBoxStyleLight = 'mapbox://styles/mapbox/streets-v11';
    mapboxgl.accessToken = MasterData.mapboxToken;
    
    this.map = new mapboxgl.Map({
      container: 'add-carrier-by-coverage',
      style: mapBoxStyleLight,
      center: this.mapService.center,
      zoom: 3
    });
    this.mapService.setMap(this.map)
    let bounds = new mapboxgl.LngLatBounds(this.mapService.bounds);
    this.map.fitBounds(bounds, { padding: { top: 100, bottom: 100, right: 300, left: 300 }, maxZoom: 7 });

    this.map.on('load', () => {
      // Mặc định add state
      this.addState();
      this.drawInit();
      // Hiển thị những vùng được chọn
      this.addSelectedArea();
      // this.popup = new mapboxgl.Popup({
      //   closeButton: false,
      //   closeOnClick: false
      //   });
      this.mapService.addPickupMark();
      this.mapService.addDropMark();
      this.mapService.addMatchAreasSource()
      this.map.on('click', (e) => {
        if (!e.clickOnLayer) {
          this.onHideAreaHightlighed();
        }
      });
    });
  }
  onHideAreaHightlighed() {
    if (this.clickAreaId) {
      this.map.setFeatureState(
        { source: this.defaultLayerId.addSelected, id: this.clickAreaId },
        { click: false }
      );
      this.clickAreaId = null;
      if (this.popup) {
        this.popup.remove();
      }
    }
  }
  actionableArea(e) {
    if (e.clickOnLayer) {
      return;
    }
    const selected = this.draw.getSelected();
    const data = this.draw.getAll();
    if (!e?.actions?.trash && !selected?.features?.length && data.features.length) {
      const feature = data.features[0];
      if (!this.featureList.find(item => item.data.id === feature.id && item.type == this.selectBtnValues.state)){
        // Khi click ra ngoài vùng được chọn thì xóa vùng đó khỏi map
        if (this.popup) {
          this.popup.remove();
        }
        this.draw.delete(feature.id);
        setTimeout(() => {
          this.onSelectBtn(this.buttonSelect);
        }, 100);
      }
    }
  }
  updateArea(e) {
    if (e.type == 'draw.render') {
      return;
    }
    if (e.clickOnLayer) {
      return;
    }
  }
  onBtnContinue() {
    let carrierIds: string[] = this.matchedCarriers.map(item => item.id)
    this.onSave(carrierIds);
    this.closeDialog();
  }
  onChangeSelectAll(val) {
    if (val && this.buttonSelect == this.selectBtnValues.state) {
      this.beforeSelectAllFeatureList = [ ...this.featureList];
      const stores = statesJson?.features;
      const removeStates = ['HA', 'AK'];
      for (let index = 0; index < stores.length; index++) {
        const store = stores[index];
        if (removeStates.includes(store.properties.SHORT_NAME)) {
          continue;
        }
        if (!this.featureList.find(item => item.data?.properties?.id === store.id)){ 
          this.featureList.push({ 
            type: this.selectBtnValues.state, 
            name: store.properties.STATE_NAME, 
            data: {
              type: 'Feature',
              geometry: store.geometry,
              properties: { ...store.properties, id: store.id, type: this.selectBtnValues.state},
              id: ulid(),
            }
          });
        }
      }
      this.updateSelectedArea();
    }
    if (!val) {
      this.featureList = this.beforeSelectAllFeatureList;
      // this.featureList = [];
      this.updateSelectedArea();

    }
  }

  addPopupAddArea(e) {
    const data = this.draw.getAll();
    const feature = data.features[0];
    let lngLat = null;
    if (feature?.properties?.isCircle) {
      lngLat = feature?.properties?.center;
    } else {
      const point = turf.centerOfMass(feature);
      lngLat = point?.geometry?.coordinates;
    }
    if (this.popup) {
      this.popup.remove();
    }
    this.popup = new mapboxgl.Popup({ closeButton: false, closeOnClick: false })
    .setLngLat(lngLat)
    .setHTML(`<div id="btn-add-area-${feature.id}"> + Add Area</div>`)
    .addTo(this.map);
    document.getElementById(`btn-add-area-${feature.id}`).parentElement.className += ' mapboxgl-popup-content-add-area';
    document.getElementById(`btn-add-area-${feature.id}`).parentNode.parentNode.firstElementChild.className += ' mapboxgl-popup-tip-add-area';
    document.getElementById(`btn-add-area-${feature.id}`).addEventListener("click", () =>{
      this.onAddAreaRadiusOrPoint(feature);
    });
  }
  
  private onAddAreaRadiusOrPoint(feature) {
    if (!feature) return
    let lngLat = null;
    if (feature?.properties?.isCircle) {
      lngLat = feature?.properties?.center;
    } else {
      const point = turf.centerOfMass(feature);
      lngLat = point?.geometry?.coordinates;
    }
    this.featureList.push({ 
      type: this.buttonSelect,
      name: `Custom Area ${this.featureList.length + 1}`,
      lngLat,
      data: {
        ...feature,
        properties: { ...feature.properties, id: feature.id, type: this.buttonSelect},
        id: ulid(),
      },
    });
    this.onSelectBtn(this.selectBtnValues.state);
    setTimeout(() => {
      this.updateSelectedArea();
    }, 100);
  }

  getRouteData() {
    if (!this.model?.jobId) return
    this.api.GET(`${Const.APIURI_JOBS}/${this.model?.jobId}/route-data`).subscribe(
      (resp) => {
        this.routeData = resp?.data?.routeData || null;
        setTimeout(() => {
          this.mapService.setupRouteOnMap(this.routeData)
        }, 2000)
      },
      (err) => {
        this.showErr(err);
      }
    );
  }

  getCarriersFromResponse(coverages = []){
    let carriers = coverages.map(item => item.carrier)
    let carrierIds = carriers.map(c => c.id)
    carrierIds = Utils.uniqElementsArray(carrierIds);
    let result = []
    for(let id of carrierIds){
      let c = carriers.find(carrier => carrier.id === id)
      if(c?.basicInfo?.name){
        result.push(c)
      }
    }
    this.matchedCarriers = result
  }

  clearSelected(){
    this.featureList = []
    this.matchedAreas = []
    const m = {
      type: 'FeatureCollection',
      features: []
    }
    this.mapService.setMatchedAreas(m)
    this.updateSelectedArea();
    this.matchedCarriers = []
  }

  getCarriersInAreas(){
    let vehicles = this.model?.vehicles ?? []
    let equipments = vehicles.map(i => i.code)
    let areas = this.featureList
    if(!areas) return;
    this.syncLoading=true
    this.api.POST(Const.APIV2(Const.APIURI_POOLS + '/get-carriers-for-bid'), {equipments, areas}).subscribe(
      (response) => {
        this.matchedAreas = response?.data?.list_data ?? []
        this.getCarriersFromResponse(this.matchedAreas)
        let areas = this.matchedAreas.map(a => a.data)
        const data = {
          type: 'FeatureCollection',
          features: areas,
        };
        this.mapService.setMatchedAreas(data)
        this.showSuccess('Sync successfully.')
        this.syncLoading=false
      },
      (err) => {
        this.showErr(err);
        this.syncLoading=false
      }
    );
  }

}