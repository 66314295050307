<form class="form-detail" [formGroup]="formInput" nz-form>
  <div class="group no-padding">
    <!-- header -->
    <div class="group-header" style="display: flex; align-items: center">
      <div style="text-wrap: nowrap !important">Contact Information</div>
      <div style="width: 100%"></div>
      <ng-container *ngIf="requirePermissions([PermissionCode.client.update])">
        <!-- edit button -->
        <button nz-button (click)="onBtnEdit()" class="btn-primary" *ngIf="!isEditing">
          <i nz-icon nzType="edit" nzTheme="outline"></i>
          Edit
        </button>
        <!-- save button -->
        <button
          nz-button
          (click)="onBtnSave()"
          class="btn-primary"
          *ngIf="isEditing"
          [disabled]="onProgress || !needUpdate"
          [nzLoading]="onProgress"
        >
          <i nz-icon nzType="save" nzTheme="outline"></i>
          Save
        </button>
      </ng-container>
    </div>
    <!-- form -->
    <div class="group-content">
      <ng-container *ngFor="let groupKey of ['primaryContact', 'payableContact']">
        <form *ngIf="!isHidden(groupKey)" nz-form [formGroupName]="groupKey">
          <div class="tdm-row cell-spacing">
            <div class="tdm-col tdm-col-5" *ngFor="let key of ['firstName', 'lastName', 'phone', 'email', 'slackId']">
              <div>
                <div class="form-label-v2">
                  {{getLabel(fullKey(groupKey, key))}}<span
                    *ngIf="isRequired(fullKey(groupKey, key))"
                    class="label-mark-required"
                  ></span>
                </div>
                <ng-container [ngSwitch]="key">
                  <div *ngSwitchCase="'phone'" [ngClass]="key">
                    <nz-form-item>
                      <nz-form-control>
                        <nz-input-group nzCompact>
                          <input nz-input [formControlName]="key"
                            [type]="getInputType(fullKey(groupKey, key))"
                            [placeholder]="getPlaceHolder(fullKey(groupKey, key))"
                            (input)="onInputChanged($event, key)"
                            (keypress)="onInputKeyPress($event, key)"
                            style="width: 70%;"
                          >

                          <input nz-input [formControlName]="'phoneExtension'"
                            [type]="getInputType(fullKey(groupKey, 'phoneExtension'))"
                            [placeholder]="getPlaceHolder(fullKey(groupKey, 'phoneExtension'))"
                            (input)="onInputChanged($event, 'phoneExtension')"
                            (keypress)="onlyNumberKey($event)"
                            style="width: 30%;"
                          >
                        </nz-input-group>
                      </nz-form-control>
                    </nz-form-item>
                  </div>

                  <div *ngSwitchDefault [ngClass]="key">
                    <nz-form-item>
                      <nz-form-control>
                        <input
                          nz-input
                          [formControlName]="key"
                          [type]="getInputType(fullKey(groupKey, key))"
                          [placeholder]="getPlaceHolder(fullKey(groupKey, key))"
                          (input)="onInputChanged($event, key)"
                          (keypress)="onInputKeyPress($event, key)"
                        />
                        <div *ngIf="groupKey == 'payableContact' && key == 'email'" class="hint-payable-email">
                          Invoices will be sent to this contact.
                        </div>
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="tdm-row cell-spacing" *ngIf="groupKey=='primaryContact'">
            <div class="tdm-col">
              <nz-form-item>
                <nz-form-control>
                  <div
                    nz-checkbox
                    *ngFor="let key of ['isPayableContact']"
                    [formControlName]="key"
                    [nzDisabled]="!isEditing"
                    (ngModelChange)="onCheckBoxPayableContactChange($event)"
                  >
                    {{getLabel(fullKey(groupKey, key))}}
                  </div>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </form>
      </ng-container>
    </div>
  </div>
</form>
