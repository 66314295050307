import { BaseFormItem } from "@app/admin/base/form-item";
import { Component, TemplateRef } from "@angular/core";
import { Utils } from "@services/utils";

export interface FilterFieldDeclaration {
  key: string,
  declaration: FormControlDeclaration,
  templateInput: TemplateRef<any>,
}

/**
 * Class chung để chuẩn hoá UI + behaviour của filter.
 */
@Component({
  selector: '[filter-layout]',
  templateUrl: './view.html',
  styleUrls: ['./style.scss']
})
export class FilterLayout extends BaseFormItem {
  private dicFilterFieldDeclaration: {[key: string]: FilterFieldDeclaration};

  get isCreateNew(): boolean { return true }
  get shouldClearFilter() { return Utils.isObjectNotEmpty(this.getFormData(true)) }

  ngOnInit(): void {
    this.initFilter();
    super.ngOnInit();
  }

  // Phải đợi host component render xong để có đầy đủ template rồi mới khởi tạo form
  ngHostAfterViewInit(filterFields: FilterFieldDeclaration[] = []) {
    this.initFormGroupDeclaration(filterFields);
    setTimeout(() => {
      // Đợi hàm ngAfterViewInit kết thúc rồi mới khởi tạo form
      this.createFormInput(this.model);
    }, 1);
  }

  // Hàm này phải gọi sau ngAfterViewInit của host component
  private initFormGroupDeclaration(filterFields: FilterFieldDeclaration[]) {
    this.formGroupDeclaration = {};
    this.dicFilterFieldDeclaration = {};
    for (let item of filterFields) {
      if (this.formGroupDeclaration[item.key]) {
        throw Error(`key ${item.key} is duplicated. ${item.declaration.label}`);
      }
      this.formGroupDeclaration[item.key] = item.declaration;
      this.dicFilterFieldDeclaration[item.key] = item;
    }
  }

  private initFilter() {
    let params = Utils.parseQueryStringFromUrl(this.router.url);
    if (params.filter) {
      this.model = JSON.parse(params.filter);
    }
  }

  // Cần gán hàm này vào ngModelChange của từng trường input trong template
  onChange(input?: {key: string, value: any}) {
    let formData: any = this.getFormData(true);
    // tách trường page, filter ra khỏi query để reset
    let {page, filter, ...query} = Utils.parseQueryStringFromUrl(this.router.url);
    if (Utils.isObjectNotEmpty(formData)) {
      query.filter = JSON.stringify(formData);
    }
    this.routeWithQueryUrl(query, true);
  }

  getTemplateFilterField(key: string) {
    return this.dicFilterFieldDeclaration[key]?.templateInput;
  }

  onBtnClearFilter() {
    for (let key of this.formInputKeys) {
      this.setItemValue(key, this.formGroupDeclaration[key].initialValue);
    }
    // tách trường page, filter ra khỏi query để reset (không có page & filter)
    let {page, filter, ...query} = Utils.parseQueryStringFromUrl(this.router.url);
    this.routeWithQueryUrl(query, true);
  }

  onChangeWithWarpIdString(input?: {key: string, value: any}) {
    let formData: any = this.getFormData(true);
    if (formData.warpId){
      formData.warpId = [Number(formData.warpId)]
    } 
    // tách trường page, filter ra khỏi query để reset
    let {page, filter, ...query} = Utils.parseQueryStringFromUrl(this.router.url);
    if (Utils.isObjectNotEmpty(formData)) {
      query.filter = JSON.stringify(formData);
    }
    this.routeWithQueryUrl(query, true);
  }

}