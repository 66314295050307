import to from 'await-to-js';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormInputUploadFile } from "@app/admin/base/form-input-upload-file";
import { BaseFormItem } from "@app/admin/base/form-item";
import { Const } from "@const/Const";
import { DateUtil } from "@services/date-utils";
import { Utils } from "@services/utils";
import {
  NotesComponentEventFetchDataDone,
  NotesComponentEventItemAdded,
  NotesComponentEventItemRemoved,
} from './interface';
import { Observable, of } from 'rxjs';

export interface CheckBeforeAddResult {
  shouldContinue: boolean
}

@Component({
  selector: "[notes-component]",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})
export class NotesComponent extends BaseFormItem implements OnInit {
  @ViewChild('uploadFilesForm', { static: false }) uploadFilesForm: FormInputUploadFile;

  private listData: any[] = [];
  private _subjectId: string;
  uploadFiles = [];

  // Thực hiện một số thao tác validation trước khi add
  @Input() beforeAdd: () => Observable<CheckBeforeAddResult> = () => of({shouldContinue: true});
  @Input() showMessageAddSuccess = () => this.showSuccess("Your Note has been added successfully.");
  @Input() hasUploadFile: boolean = false;
  @Input() get subjectId() {
    return this._subjectId
  };
  set subjectId(value) {
    this._subjectId = value;
    this.fetchData();
  }
  // Hiện tại ko cho delete note, tuy nhiên component dùng chung cho nhiều màn hình
  // Màn hình nào cần delete thì gán allowDeletion = true
  @Input() allowDeletion = false;
  @Input() allowDeleteItemNotMine = false;      // cho phép delete những item ko phải do mình tạo (hiện tại chỉ áp dụng cho màn confirm POD)
  @Input() subjectType;
  @Input() visible = true;
  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() fetchDataDone = new EventEmitter<NotesComponentEventFetchDataDone>();
  @Output() itemAdded = new EventEmitter<NotesComponentEventItemAdded>();
  @Output() itemRemoved = new EventEmitter<NotesComponentEventItemRemoved>();
  newNoteMsg: string;
  edittingId: number = -1;
  items: any = {};
  pinnedItems = [];
  isLoading: boolean = true;
  constructor() {
    super();
  }

  ngOnInit(): void {
    this.fetchData();
  }

  formatData(data) {
    let items = {};
    let pinnedItems = [];
    for (let note of data) {
      if (Utils.isArrayNotEmpty(note?.imageUploadFilesArr)) {
        for (let item of note.imageUploadFilesArr) {
          item.fileUrl = this.attachedFileUrl(item);
        }
      }
      let date = note.insert?.when;

      if (note.pinned?.when && note.pinned?.by) {
        pinnedItems.push(note);
        note['time'] = DateUtil.dateToString(date, "MM/DD/YY hh:mm A");
        continue;
        //nếu note được pined thì đẩy lên đầu và không hiển thị lại ở phía dưới.
      }

      note['time'] = DateUtil.dateToString(date, "hh:mm A")


      //group note by day
      if (DateUtil.isToday(date)) date = "Today";
      else if (DateUtil.isYesterday(date)) date = "Yesterday";
      else date = DateUtil.dateToString(date, Const.FORMAT_GUI_DATE);


      if (!items[date]) items[date] = [];
      items[date].push(note);
    }
    this.pinnedItems = pinnedItems.sort(function (a, b) {
      let aDate = new Date(a.pinned.when);
      let bDate = new Date(b.pinned.when);
      return aDate.getTime() < bDate.getTime() ? 1 : (aDate.getTime() > bDate.getTime() ? -1 : 0)
    })
    return items;
  }

  fetchData() {
    if (!this.subjectId || !this.subjectType) {
      return;
    }
    this.startProgress();
    this.isLoading = true;
    this.items = {};
    this.api.GET(`${Const.APIURI_CONVERSATIONS}/?subjectId=${this.subjectId}&subjectType=${this.subjectType}&type=note`).subscribe(
      (resp) => {
        this.listData = resp?.data?.list_data || [];
        this.items = this.formatData(this.listData);
        this.fetchDataDone.emit({countTotal: this.countTotalItems()});
        this.stopProgress();
        this.isLoading = false;
      },
      (err) => {
        // this.showErr(err);
        this.stopProgress();
        this.isLoading = false;
      }
    );
  }

  close = () => {
    this.newNoteMsg = "";
    this.visibleChange.emit(false);
  };

  onConfirm = () => {
    this.beforeAdd().subscribe((result: CheckBeforeAddResult) => {
      if (result.shouldContinue) {
        this.onAdd();
      }
    });
  }

  onAdd = () => {
    if (!this.newNoteMsg && this.uploadFiles.length == 0) return;
    //call API for add new note
    this.startProgress();
    this.isLoading = true;
    let formData = new FormData();
    const jsonData = {
      'content': this.newNoteMsg,
      'countImages': this.uploadFiles.length
    }
    formData.append("params", JSON.stringify(jsonData));
    let count = 0;
    for (let image of this.uploadFiles) {
      formData.append(`uploadNoteImages.${count}`, image.file, image.fileName);
      count++;
    }
    const countBeforeAdd = this.countTotalItems();
    this.api
      .postFormData(`${Const.APIURI_CONVERSATIONS}/?subjectId=${this.subjectId}&subjectType=${this.subjectType}&type=note`, formData)
      .subscribe(
        (resp) => {
          this.showMessageAddSuccess();
          this.itemAdded.emit({item: resp.data, countBeforeAdd})
          this.newNoteMsg = "";
          this.uploadFiles = [];
          this.stopProgress();
          this.isLoading = false;
          this.fetchData();
          this.uploadFilesForm?.resetFormInput();
        },
        (err) => {
          this.showErr(err);
          this.newNoteMsg = "";
          this.uploadFiles = [];
          this.stopProgress();
          this.isLoading = false;
          this.uploadFilesForm?.resetFormInput();
        }
      );
  };

  onEdit = (item) => {
    this.newNoteMsg = item.content;
    this.edittingId = item.id;
  };

  onDelete = (item, list) => {
    item.isHilight = true;
    let afterClose = new EventEmitter();
    afterClose.subscribe(() => item.isHilight = false);
    let message = `Delete note?`;
    if (!this.isMine(item)) {
      let authorName = this.getAuthorName(item);
      message = `Delete <b>${authorName}'s</b> note?`;
    }
    this.confirmDeletion({message, fnOk: () => this.deleteItem(item, list), afterClose});
  }

  getAuthorName(item) {
    return this.getFullName(item.user) || 'No name';
  }

  private async deleteItem(item: any, list: any[]) {
    item.isLoading = true;
    const countBeforeRemove = this.countTotalItems();
    let [err] = await to(this.api.DELETE(`${Const.APIURI_CONVERSATIONS}/${item.id}`).toPromise());
    if (err) {
      this.showErr(err);
    } else{
      this.itemRemoved.emit({item, countBeforeRemove});
      let index = list.indexOf(item);
      if (index >= 0) {
        list.splice(index, 1);
      }
    }
    item.isLoading = false;
  }

  onSubmitEdit = () => {
    this.startProgress();
    this.isLoading = true;
    this.api
      .PUT(
        `${Const.APIURI_CONVERSATIONS}/${this.edittingId}/?subjectId=${this.subjectId}&subjectType=${this.subjectType}&type=note`,
        {
          content: this.newNoteMsg,
        }
      )
      .subscribe(
        (resp) => {
          this.isLoading = false;
          this.showSuccess("Your Note has been edited successfully.");
          this.onCancelEdit();
          this.fetchData();
          this.stopProgress();
        },
        (err) => {
          this.showErr(err);
          this.onCancelEdit();
          this.stopProgress();
          this.isLoading = false;
        }
      );
  };

  onCancelEdit = () => {
    this.edittingId = -1;
    this.newNoteMsg = "";
  };

  onBtnPin(item) {
    this.startProgress();
    this.isLoading = true;
    this.api
      .PUT(
        `${Const.APIURI_CONVERSATIONS}/${item.id}/pin?subjectId=${this.subjectId}&subjectType=${this.subjectType}&type=note`,
        { pin: true }
      )
      .subscribe(
        (resp) => {
          this.isLoading = false;
          this.showSuccess("Your Note has been pinned successfully.");
          this.fetchData();
          this.stopProgress();
        },
        (err) => {
          this.showErr(err);
          this.stopProgress();
          this.isLoading = false;
        }
      );
  }

  onBtnUnPin(item) {
    this.confirmDeletion({
      message: "Do you want to unpin this note?",
      txtBtnOk: this.txtDelete,
      fnOk: () => {
        this.startProgress();
        this.isLoading = true;
        this.api
          .PUT(
            `${Const.APIURI_CONVERSATIONS}/${item.id}/pin?subjectId=${this.subjectId}&subjectType=${this.subjectType}&type=note`,
            { pin: false }
          )
          .subscribe(
            (resp) => {
              this.isLoading = false;
              this.showSuccess("Your Note has been un-pinned successfully.");
              this.fetchData();
              this.stopProgress();
            },
            (err) => {
              this.showErr(err);
              this.stopProgress();
              this.isLoading = false;
            }
          );
      },
    });
  }

  getDateItems() {
    return Object.keys(this.items);
  }

  private countTotalItems(): number {
    let count = this.pinnedItems.length;
    let dates = this.getDateItems();
    for (let date of dates) {
      count += this.items[date].length;
    }
    return count;
  }

  get shouldShowBtnAddNote() {
    if (this.isLoading) return false;
    if (!this.newNoteMsg && this.uploadFiles.length == 0) return false;
    return true;
  }

  get shouldShowBtnCancel() {
    if (this.isLoading) return false;
    if (this.edittingId != -1 || this.newNoteMsg || this.uploadFiles.length > 0) return true;
    return false;
  }

  getLabelBtnAdd() {
    if (this.edittingId != -1) return 'Update';
    return 'Add';
  }

  private isMine(item): boolean {
    return this.authUser?.id && this.authUser?.id == item?.user?.id;
  }

  canDelete(item): boolean {
    if (!this.allowDeletion) {
      return false;
    }
    if (this.allowDeleteItemNotMine) {
      return true;
    }
    if (!this.isMine(item)) {
      return false;
    }
    return true;
  }

  isHilight(item): boolean {
    return item.isHilight == true;
  }

  onFileSelectedChange(files) {
    this.uploadFiles = files;
  }
}
