<form class="form-detail" [formGroup]="formInput" nz-form>
  <div class="group no-padding">
    <!-- header -->
    <div class="group-header" style="display: flex; align-items: center">
      <div style="text-wrap: nowrap !important">Notification Recipients</div>
      <div style="width: 100%"></div>
      <ng-container *ngIf="requirePermissions([PermissionCode.client.update])">
        <!-- edit button -->
        <button nz-button (click)="onBtnEdit()" class="btn-primary" *ngIf="!isEditing">
          <i nz-icon nzType="edit" nzTheme="outline"></i>
          Edit
        </button>
        <!-- save button -->
        <button
          nz-button
          (click)="onBtnSave()"
          class="btn-primary"
          *ngIf="isEditing"
          [disabled]="onProgress || !needUpdate"
          [nzLoading]="onProgress"
        >
          <i nz-icon nzType="save" nzTheme="outline"></i>
          Save
        </button>
      </ng-container>
    </div>
    <!-- form -->
    <div class="group-content">
      <ng-container *ngFor="let groupKey of ['contacts'];" [formArrayName]="groupKey">
        <form nz-form *ngFor="let item of getArrayControls(groupKey); let i = index" [formGroupName]="i">
          <div class="tdm-row cell-spacing">
            <div class="tdm-col tdm-col-5" *ngFor="let key of ['firstName', 'lastName', 'phone', 'email', 'action']">
              <div *ngIf="key !== 'action'">
                <div class="form-label-v2">
                  {{getLabel(fullKey(groupKey, key))}}
                  <span *ngIf="isRequired(fullKey(groupKey, key))" class="label-mark-required"></span>
                </div>
                <nz-form-item>
                  <nz-form-control>
                    <input
                      nz-input
                      [formControlName]="key"
                      [type]="getInputType(fullKey(groupKey, key))"
                      [placeholder]="getPlaceHolder(fullKey(groupKey, key))"
                      (input)="onInputChanged($event, key)"
                      (keypress)="onInputKeyPress($event, key)"
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div *ngIf="key === 'action' && (isEditing || isCreateNew)" [ngClass]="key">
                <div class="form-label-v2">&nbsp;</div>
                <nz-form-item>
                  <nz-form-control>
                    <nz-space class="group-button">
                      <ng-container *ngIf="!isAdminReadOnlyRole && (isCreateNew || isEditing)">
                        <span
                          *nzSpaceItem
                          nz-icon
                          nzType="plus-circle"
                          nzTheme="outline"
                          (click)="addItemToFormArray(groupKey)"
                        ></span>
                      </ng-container>

                      <ng-container *ngIf="i > 0 && !isAdminReadOnlyRole && (isCreateNew || isEditing)">
                        <span
                          *nzSpaceItem
                          nz-icon
                          nzType="minus-circle"
                          nzTheme="twotone"
                          [nzTwotoneColor]="'#ff4d4f'"
                          (click)="removeItemInFormArray(groupKey, i)"
                        ></span>
                      </ng-container>
                    </nz-space>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
          </div>
        </form>
      </ng-container>
    </div>
  </div>
</form>
