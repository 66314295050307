import { Component } from "@angular/core";
import { Const } from "@const/Const";
import { ApiService } from "@services/api.service";
import { InputHelper } from "@app/services/input-helper";
import _ from 'underscore';
import { DialogService } from "@dialogs/dialog.service";
import { EditCarrierBidAnswerComponent } from "../edit-carrier-bid-answer/edit-carrier-bid-answer.component";
import { DateUtil } from "@services/date-utils";
import { getDashboard } from "@services/index";
import { BaseDetail } from "@app/admin/base/detail";
import { ActivatedRoute } from "@angular/router";
import { SendMessageComponent } from "../send-message/send-message.component";
import { EditNoteComponent } from "../edit-note/edit-note.component";
import { ViewPreviousRouteComponent } from "../view-previous-route/view-previous-route.component";
import { BizUtil } from "@services/biz";
import { AddCarrier } from "./add-carrier";
import { AddCarrierPool } from "./add-carrier-pool";
import { CommLogList } from "@components/comm-log";
import { AddCarrierPoolByCoverage } from "./add-carrier-pool-by-coverage";
import { SelectCarrierByServiceArea } from "./add-carrier-by-coverage";
import { ListShipmentExpandUtil } from "@services/list-shipment-expand-util";
import { Utils } from "@services/utils";
import { AssignCarrier } from "@app/admin/carrier-bids/components/assign-carrier";
import { EMPTY, Subject } from "rxjs";
import { debounceTime, switchMap } from "rxjs/operators";
import { Log } from "@services/log";
import { Const as WarpConst } from "@wearewarp/universal-libs";

const API_URLS = {
  GET_CARRIER_BIDS: Const.APIURI_CARRIER_BIDS,
};
const CHECKSTATUS = {
  NO_ITEM_CHECKED: 0,
  AT_LEAST_ONE_ITEM_CHECKED: 1,
  ALL_ITEMS_CHECKED: 2
}
@Component({
  selector: "app-detail-carrier-bid",
  templateUrl: "./detail-carrier-bid.component.html",
  styleUrls: ["./detail-carrier-bid.component.scss"],
})
export class DetailCarrierBidComponent extends BaseDetail {

  public isError = false;
  public isLoading = false;
  public data: any = {};
  public isShowAddCarrierModal = false
  public arrBidFromPublic: any[] = [];
  protected get crudEntity(): string { return 'carrier_bids' }
  public keywordSearchName: string;
  public keywordSearchPoolName: string;
  public keywordSearchContacts: string;
  public listData: any = [];
  public listPool: any = [];
  public shipments: any[] = [];
  public sortPriceDirection = 'ascend';
  public isSourceMarketPlace: boolean = false;
  protected formGroupDeclaration: FormGroupDeclaration = {
    pickupAddress: {
      label: "Pickup Address",
      placeHolder: "Pickup Address",
      required: true,
    },
    dropoffAddress: {
      label: "Drop-off Address",
      placeHolder: "Drop-off Address",
      required: true,
    },
    pickupDate: {
      label: "Pickup Date Time",
      placeHolder: "Pickup Date Time",
      required: true,
    },
    dropoffDate: {
      label: "Dropoff Date Time",
      placeHolder: "Dropoff Date Time",
      required: true,
    },
    serviceOptions: {
      label: "Service Options",
      placeHolder: "Service Options",
    },
    equipments: {
      label: "Equipments",
      placeHolder: "Equipments",
    },
    carrierIds: {
      required: true,
      label: "Carriers",
    },
    basePrice: {
      label: "Base Price",
      placeHolder: "Enter base price",
      required: true,
      getValue: InputHelper.getValueMoney, formatValue: InputHelper.formatMoney2
    },
    type: {
      label: "Bid Mode",
      required: true,
      initialValue: Const.CarrierBidTypes.manual
    },
    isShowBasePrice: {
      label: "Show Base Price For Carrier",
      placeHolder: "",
      initialValue: false
    },
    isAllowPlaceBid: {
      label: "Allow the carrier place bid",
      placeHolder: "",
      initialValue: false
    },
    isShowOnBidBoard: {
      label: "Show on Bid Board",
      placeHolder: "",
      initialValue: false
    },
    isRequireCarrierAcceptLoadTender: {
      label: "Carrier needs to accept load tender after route assignment",
      placeHolder: "",
      initialValue: false
    },
  };

  protected subscribeTopbarTitle(fn: Function) {
    return fn("View Carrier Bid");
  }

  private listCarrierOriginal: any[];     // lưu mảng carrier ban đầu trước khi convert/group
  public listCarrierWithGroup: any[];     // mảng sau khi đã group theo dedicated pool

  public avgCostOfRoutes: any //giá bid trung bình của route này
  public earliestBid: any //thời gian phản hồi bid sớm nhất
  public lowestPrice: any //giá bid thấp nhất
  public bestExperience: any //chạy tuyến hiện tại nhiều nhất
  public basePrice: any //giá bid mà WARP offer
  public acceptedPrice: any //giá bid đã chốt
  public acceptedCarrier: any

  public get checkedStatus() {
    const carriers = this.data.carriers || [];
    let countChecked = carriers.filter(carrier => carrier.checked).length
    if (countChecked == 0) return CHECKSTATUS.NO_ITEM_CHECKED;                   // No item is checked
    if (countChecked == carriers.length) return CHECKSTATUS.ALL_ITEMS_CHECKED;     // All items are checked
    return CHECKSTATUS.AT_LEAST_ONE_ITEM_CHECKED;                                         // A few items (not all) are checked
  }

  shouldShowBtnDat = false;
  datPostExist = false;

  constructor(activatedRoute: ActivatedRoute, api: ApiService) {
    super(activatedRoute);
    this.api = api;
    this.activatedRoute.params.subscribe(params => {
      super.handleNavigationEnd(activatedRoute.toString(), activatedRoute.queryParamMap);
    })
  }

  public jobHyperLink;

  public get carrierBidId() {
    return this.id
  }
  ngOnInit(): void {
    super.ngOnInit();
    this.searchSubject.pipe(
      debounceTime(150),
      switchMap((query: string) => {
        this.onFilterData();
        return EMPTY;
      })
    ).subscribe(result => { });
    setTimeout(() => getDashboard().sideBar.isSmallWidth = true, 1);
    this.getDatLoad();
  }

  public mapOfExpandedData: { [key: string]: TreeNodeInterface[] } = {};

  toggleChildren(originalItem: TreeNodeInterface, mappedItem: TreeNodeInterface) {
    ListShipmentExpandUtil.toggleChildren(originalItem, mappedItem, this.mapOfExpandedData);
  }

  onExpandChange(array: TreeNodeInterface[], data: TreeNodeInterface, isExpanded: boolean) {
    ListShipmentExpandUtil.collapse(array, data, isExpanded);
  }

  getTreeNodeId(mappedItem: TreeNodeInterface) {
    let item: any = mappedItem.parent ?? mappedItem;
    return item.id;
  }

  getTagsData(listData) {
    let bidDates: number[] = []
    let bidPrices: number[] = []
    let countLanes: number[] = []

    for (let item of listData) {
      //date
      const when = item?.when && new Date(item?.when)?.getTime()
      if (when) bidDates.push(when)
      //price
      const price = !isNaN(item?.price) && item?.price
      if (price) bidPrices.push(price)
      //count lane completed
      const countLane = !isNaN(item?.countLane) && item?.countLane
      if (countLane) countLanes.push(countLane)
    }
    return {
      earliestBid: Math.min(...bidDates),
      lowestPrice: Math.min(...bidPrices),
      bestExperience: Math.max(...countLanes)
    }
  }

  private addTagsData(listData, earliestBid, lowestPrice, bestExperience) {
    let newData = []
    for (let item of listData) {
      //isEarliestBid
      if (item?.when && new Date(item.when).getTime() == earliestBid) item.isEarliestBid = true
      //isLowestPrice
      if (item?.price && item?.price == lowestPrice) item.isLowestPrice = true
      //bestExperience
      if (item?.countLane && item?.countLane == bestExperience) item.isBestExperience = true

      newData.push(item)
    }
    return newData
  }

  private processListCarrier(listData) {
    this.mapOfExpandedData = ListShipmentExpandUtil.processListData(listData);
    const listCarrierNoGroup = [];
    for (let arr of Object.values(this.mapOfExpandedData)) {
      for (let item of arr) {
        if (this.isGroupByPool(item)) {
          continue;
        }
        listCarrierNoGroup.push(item);
      }
    }
    this.data.carriers = listCarrierNoGroup;
  }

  isGroupByPool(item): boolean {
    return (<string>item.key)?.startsWith('pool-');
  }
  optionalEquipments(vehicles) {
    if (!vehicles?.length) {
      return 'N/A';
    }
    return vehicles.map(it => it.label).join(', ');
  }

  // Những thằng nào nằm trong cùng 1 dedicated pool thì gom thành 1 nhóm
  private groupByDedicatedPool(bidDetail) {
    const carriers: any[] = bidDetail.carriers ?? [];
    const pools: any[] = bidDetail.pools ?? [];
    const dedicatedPools = {};
    for (let pool of pools) {
      if (pool.isDedicatedPool) {
        dedicatedPools[pool.id] = pool;
      }
    }
    const arr = [];
    const dicGroups = {};
    for (let carrier of carriers) {
      carrier.key = `carrier-${carrier.carrierId}`;     // dùng cho TreeNodeInterface
      let isDedicatedPool = false
      let poolData: any = { isDedicatedPool: false }
      if (carrier.poolId && dedicatedPools[carrier.poolId]) {
        isDedicatedPool = true
        poolData = {
          isDedicatedPool: true,
          poolName: dedicatedPools[carrier.poolId].name
        }
      }
      // if (carrier.poolId && dedicatedPools[carrier.poolId]) {
      //   let group = dicGroups[carrier.poolId];
      //   if (!group) {
      //     group = {
      //       key: `pool-${carrier.poolId}`,
      //       poolName: dedicatedPools[carrier.poolId].name,
      //       children: []
      //     };
      //     dicGroups[carrier.poolId] = group;
      //   }
      //   group.children.push(carrier);
      // } else {
      //   arr.push(carrier);
      // }
      arr.push({ ...carrier, ...poolData });
    }

    if (!Utils.isObjectNotEmpty(dicGroups)) return arr;

    let groups = this.sortPoolGroups(Object.values(dicGroups));
    for (let group of groups) {
      group.children = this.sortCarriers(group.children);
    }
    return [...groups, ...arr];
  }

  private sortPoolGroups(pools: any[]) {
    return pools
    // return pools.sort((a: any, b: any) => {
    //   const nameA = a.name.toUpperCase(); // ignore upper and lowercase
    //   const nameB = b.name.toUpperCase(); // ignore upper and lowercase
    //   if (nameA < nameB) {
    //     return -1;
    //   }
    //   if (nameA > nameB) {
    //     return 1;
    //   }
    //   // names must be equal
    //   return 0;
    // })
  }

  private sortCarriers(carriers: any[]) {
    const sortPriceDirection = this.sortPriceDirection;
    return _(carriers).chain()
      .sortBy(function (patient) {
        return patient.title;
      })
      .sortBy(function (patient) {
        return (patient.status) * -1; //reverse sort => sort status desc
      })
      .sortBy(function (patient) {
        if (sortPriceDirection == 'descend') return (patient.price || 0) * -1;
        return (patient.price || 0); //reverse sort => sort price desc
      })
      .sortBy(function (patient) {
        return patient.lastSent ? -1 : 0; //sort lastSent. những carrier đã sent email sẽ lên trước.
      })
      .sortBy(function (patient) {
        return (patient.state || 0) * -1; //reverse sort => sort tag desc
      })
      .value();
  }

  getData() {
    if (!this.carrierBidId) return;
    this.createFormInput();
    this.setEnableFormGroup(false);
    this.isLoading = true;

    let urls = [
      `${API_URLS.GET_CARRIER_BIDS}/${this.carrierBidId}`,
      `${API_URLS.GET_CARRIER_BIDS}/${this.carrierBidId}/get_list_carriers`
    ];
    this.api.concurrentGET(urls).subscribe(
      resp => {
        const bid = resp[0].data;
        const carriers = resp[1].data.list_data;
        this.processDataBid({ ...bid, carriers })
        this.isLoading = false;
      }, err => {
        this.isLoading = false;
        this.showErr(err);
        this.isError = true;
      }
    );
    this.getDataBidPublic();
  }

  private getDataBidPublic() {
    if (!this.carrierBidId) return;
    this.api.GET(`${API_URLS.GET_CARRIER_BIDS}/${this.carrierBidId}/bid-public`).subscribe(
      (response) => {
        let list_data = response?.data?.list_data || [];
        list_data = list_data.map(item => {
          if (item.dotMcLookupData) {
            const mcDotInfo = this.parseDotMcLookupData(item.dotMcLookupData);
            if (Utils.isObjectNotEmpty(item.dotMcLookupData)) {
              item.mcDotInfo = mcDotInfo;
            }
          }
          return item;
        });
        this.arrBidFromPublic = list_data;
      },
      (err) => { }
    );
  }

  private parseDotMcLookupData(data) {
    let getAddress = (data) => {
      if (!data) return '';
      const { street_address, city_state_zip } = data;
      return `${street_address}, ${city_state_zip}`;
    }
    let result: any = {}
    if (data.yat && (data.yat.dot_number || data.yat.mc_number)) {
      let contacts = data.yat?.contacts ?? [];
      let [contact] = contacts;
      let locations = data.yat?.locations ?? [];
      let [location] = locations;

      result = {
        companyName: data.yat.carrier_name,
        addressText: getAddress(location),
        address: {},
        firstName: contact?.first_name,
        lastName: contact?.last_name,
        phone: contact?.phone_number ? InputHelper.formatPhone(Utils.onlyNumbers(contact?.phone_number)) : null,
        originPhone: contact?.phone_number ? InputHelper.formatPhone(Utils.onlyNumbers(contact?.phone_number)) : null,
        email: contact?.email_address,
        originEmail: contact?.email_address,
        dot: data.yat.dot_number,
        mc: data.yat.mc_number
      }
      if (location && location.street_address && location.city) {
        result.address['street'] = location.street_address;
        result.address['city'] = location.city;
        result.address['state'] = location.state_prov;
        result.address['zipcode'] = location.zip_code;
      }
    }
    else if (data.fmcsa && (data.fmcsa.dotNo || data.fmcsa.mcNo)) {
      let obj = Utils.splitName(data.fmcsa.name);
      result = {
        companyName: data.fmcsa.name,
        addressText: data.fmcsa.physicalAddress,
        address: {},
        firstName: obj.firstName,
        lastName: obj.lastName,
        phone: data.fmcsa?.phone ? InputHelper.formatPhone(Utils.onlyNumbers(data.fmcsa.phone)) : null,
        originPhone: data.fmcsa.phone ? InputHelper.formatPhone(Utils.onlyNumbers(data.fmcsa.phone)) : null,
        email: data.fmcsa?.mailingAddress,
        originEmail: data.fmcsa?.mailingAddress,
        dot: data.fmcsa.dotNo,
        mc: data.fmcsa.mcNo
      }

      if (!result.phone && !result.email) {
        result = { ...result, firstName: null, lastName: null, isEdit: true }
      }
    }
    else if (data.goHightWay) {
      let obj = Utils.splitName(data.goHightWay.name);
      result = {
        companyName: data.goHightWay.name,
        addressText: this.getAddressText(data.goHightWay.address),
        address: data?.goHightWay.address,
        firstName: obj.firstName,
        lastName: obj.lastName,
        phone: data.goHightWay?.phone ? InputHelper.formatPhone(Utils.onlyNumbers(data.goHightWay.phone)) : null,
        originPhone: data.goHightWay.phone ? InputHelper.formatPhone(Utils.onlyNumbers(data.goHightWay.phone)) : null,
        email: data.goHightWay?.email,
        originEmail: data.goHightWay?.email,
        dot: data.goHightWay.dot,
        mc: data.goHightWay.mc
      }

      if (!result.phone && !result.email) {
        result = { ...result, firstName: null, lastName: null, isEdit: true }
      }
    }

    return result;
  }

  calcPercentage(x, y) {
    if (!x || !y || isNaN(x) || isNaN(y)) return
    const percent = ((x / y) * 100) - 100
    if (!percent) return
    return `${(percent).toFixed(2)}%`;
  }

  formatMoney(money: number) {
    return InputHelper.formatMoney2((money).toFixed(2).toString());
  }

  compareWithOtherPrice(text: string, price: number) {
    if (!price || isNaN(price) || !isFinite(price)) return
    if (this.acceptedPrice > price) {
      return {
        status: Const.compareBidPrice.more,
        percent: this.calcPercentage(this.acceptedPrice, price),
        text: `more than ${text} ${this.formatMoney(this.acceptedPrice - price)}`
      }
    }
    if (this.acceptedPrice == price) {
      return {
        status: Const.compareBidPrice.equal,
        percent: this.calcPercentage(this.acceptedPrice, price),
        text: `Equal to ${text} ${this.formatMoney(this.acceptedPrice)}`
      }
    }
    if (this.acceptedPrice < price) {
      return {
        status: Const.compareBidPrice.less,
        percent: this.calcPercentage(price, this.acceptedPrice),
        text: `less than ${text} ${this.formatMoney(price - this.acceptedPrice)}`
      }
    }
    return
  }

  getCompareBidPriceColor(status) {
    switch (status) {
      case Const.compareBidPrice.more:
        return 'red'
      case Const.compareBidPrice.equal:
      case Const.compareBidPrice.less:
        return 'green'
    }
  }

  getCompareBidPriceIcon(status) {
    switch (status) {
      case Const.compareBidPrice.more:
        return 'arrow-up'
      case Const.compareBidPrice.equal:
        return 'check'
      case Const.compareBidPrice.less:
        return 'arrow-down'
    }
  }


  get compareWithLowestPrice() {
    return this.compareWithOtherPrice('lowest price', this.lowestPrice)
  }

  get compareWithMarketPrice() {
    return this.compareWithOtherPrice('market price', this.avgCostOfRoutes)
  }

  get compareWithOfferPrice() {
    return this.compareWithOtherPrice('offer price', this.basePrice)
  }

  private processDataBid(data) {
    if (data.job) this.jobHyperLink = BizUtil.createHyperLinkForJob(data.job);

    //convert data
    this.data = data;
    this.listCarrierOriginal = data.carriers;
    this.listCarrierWithGroup = this.sortCarriers(this.groupByDedicatedPool(data));
    this.listData = this.listCarrierWithGroup;

    //start: xử lý earliest bid, lowest price, route completed
    const { earliestBid, lowestPrice, bestExperience } = this.getTagsData(this.listData)
    this.earliestBid = earliestBid
    this.bestExperience = bestExperience //chạy tuyến này nhiều nhất
    this.lowestPrice = lowestPrice //giá thấp nhất
    this.basePrice = this.data?.basePrice //giá offer
    this.getAvgCostOfRoutes(this.data?.jobId) //giá trung bình
    this.listCarrierWithGroup = this.addTagsData(this.listCarrierWithGroup, earliestBid, lowestPrice, bestExperience)
    // xử lý info cho accepted carrier
    this.acceptedPrice = this.data?.job?.assignedCarrier?.cost?.grandTotal
    this.acceptedCarrier = this.data?.job?.carrier
    const findCarrier = this.listCarrierWithGroup?.find(carrier => carrier?.carrierId == this.acceptedCarrier?.id)
    if (findCarrier) {
      this.acceptedCarrier = {
        ...this.acceptedCarrier,
        isLowestPrice: findCarrier?.isLowestPrice,
        isEarliestBid: findCarrier?.isEarliestBid,
        isBestExperience: findCarrier?.isBestExperience
      }
    }
    //end: xử lý earliest bid, lowest price, route completed

    this.processListCarrier(this.listCarrierWithGroup);

    const pickupTimeZone = data["pickupAddress"]?.metadata?.timeZoneStandard;
    const dropoffTimeZone = data["dropoffAddress"]?.metadata?.timeZoneStandard;
    data['pickupDate'] = data['pickupDate'] ? DateUtil.convertLocalTime2(data['pickupDate'], pickupTimeZone) : null;
    data['dropoffDate'] = data['dropoffDate'] ? DateUtil.convertLocalTime2(data['dropoffDate'], dropoffTimeZone) : null;

    data.serviceOptions = data.serviceOptions.map((item) => item._id);
    data.equipments = data.equipments.map((item) => item.id);
    data.pickupAddressObj = data.pickupAddress
    data.dropoffAddressObj = data.dropoffAddress
    data.pickupAddress = this.getAddressTxtCarrierBid(data.pickupAddress);
    data.dropoffAddress = this.getAddressTxtCarrierBid(data.dropoffAddress);
    this.setFormValues(data);

    this.model = data;
    this.getListPool();
    this.processShipmentsOnHold(this.model?.jobId);
    
    if (data.job?.shipments?.length) {
      this.data.populated = true
    }
    if (data.isSourceMarketPlace) {
      this.isSourceMarketPlace = true;
    }
    this.getGhostShipmentInfo();
  }

  private processShipmentsOnHold(jobId: string) {
    if (!jobId) return;
    const url = Const.APIV2(`${Const.APIURI_JOBS}/${jobId}/${Const.APIURI_SHIPMENTS}`);
    this.api.GET(url).subscribe(
      resp => {
        this.shipments = resp?.data?.list_data;
        this.processOnHold();
      }, err => {
        this.showErr(err);
      }
    );
  }

  onHold: string[] = []
  processOnHold() {
    this.onHold = (this.shipments || []).filter(it => it.tags && it.tags.indexOf('HOLD') >= 0).map(it => it.warpId);
  }

  getAddressTxtCarrierBid(addr) {
    if (!addr) return '';
    let txt = `${addr.city}, ${addr.state}, ${addr.zipcode}`;
    if (addr.street) {
      txt = `${addr.street}, ${txt}`;
    }
    return txt;
  }

  private getAvgCostOfRoutes(jobId) {
    this.api.POST(`${Const.APIV2(Const.APIURI_JOBS)}/avg-cost-of-routes`, { jobIds: [jobId] }).subscribe(
      resp => {
        const jobs = resp?.data?.list_data?.[0]?.jobs
        this.avgCostOfRoutes = this.cacAvgPastRates(jobs ?? [])
      }, err => {
        this.showErr(err);
        Log.e(err);
      }
    );
  }

  private cacAvgPastRates(jobs) {
    let num = 0;
    let total = 0;
    for (const job of jobs) {
      const grandTotal = job?.assignedCarrier?.cost?.grandTotal;
      if (grandTotal) {
        num++;
        total += grandTotal;
      }
    }
    return total / num
    // if (num > 0) {
    //   return InputHelper.formatMoney2((total / num).toFixed(2).toString());
    // }
    // return 'N/A'
  }

  onBtnAcceptBid(carrier: any) {
    let cost = this.model.baseRate?.grandTotal === carrier.price ? this.model.baseRate : this.makeCost(carrier)
    DialogService.openFormDialog1(AssignCarrier, {
      nzComponentParams: {
        jobId: this.data.jobId,
        isRequireCarrierAcceptLoadTender: this.model?.isRequireCarrierAcceptLoadTender ?? false,
        matchedCarrier: carrier,
        cost: cost,
        job: this.data?.job,
        closeOnSuccess: true,
        updateSuccess: resp => {
          this.onBtnRefresh()
          this.showDialog(`Carrier has been assigned successfully.<br /><br />
            <a href="${this.gotoDispatch()}" target="_blank">Go to dispatch</a>
          `);
        }
      },
      nzClassName: 'modal-no-padding assign-carrier-form',
    });
    // this.confirmYesNo('This action will accept bid for this carrier. Please confirm you are sure you want to perform this action.', async () => this.acceptBid(carrier))
  }

  onBtnHistoryStatus(item) {
    const { contacts } = item;
    let allHistories = [];
    for (let contact of contacts) {
      let histories = contact?.histories || [];
      if (histories.length) histories = histories.map(it => {
        return { ...it, email: contact.contactEmail, phone: contact.contactPhone }
      })
      allHistories = [...allHistories, ...histories];
    }

    allHistories = allHistories.sort(function (a, b) {
      let bDate = new Date(b.when)
      let aDate = new Date(a.when)
      return Number(bDate) - Number(aDate)
    });

    if (!allHistories.length) return;
    DialogService.openFormDialog1(CommLogList, {
      nzComponentParams: {
        model: { histories: _.uniq(allHistories, it => it.logId) },
        closeOnSuccess: true
      },
      nzClassName: "comm-log-form modal-no-padding modal-xl",
    });
  }

  $asSentStatusText = (status) => {
    if (!status) return '';
    status = this.mapStatus(status);

    return this.capitalizeFirstLetter(status);
  };

  mapStatus(status) {
    if (!status) return '';
    status = status.toLowerCase();
    const statuses = {
      pending: 'pending',
      sent: 'sent',
      failed: 'failed',
      success: 'success',
      delivered: 'delivered',
      opened: 'opened',
      clicked: 'clicked',
      unsubscribed: 'unsubscribed',
      undelivered: 'undelivered',
      invalid: 'invalid',
      unreachable: 'unreachable',
      unknown: 'unknown'
    }

    return statuses[status] || status;
  }

  $asSentStatusColor = (status) => {

    switch (status) {
      case 'pending':
        return 'gray';
      case 'sent':
      case 'success':
        return 'green';
      case 'failed':
      case 'invalid':
        return 'red';
      case 'delivered':
      case 'opened':
      case 'clicked':
        return 'blue';
      case 'undelivered':
      case 'unreachable':
      case 'unknown':
        return 'orange';
      case 'unsubscribed':
        return 'black';
      default:
        return '';
    }

    const statuses = {
      processed: 'gray',
      delivered: 'blue',
      deferred: 'red',
      dropped: 'red',
      bounce: 'red',
      open: 'green',
      click: 'green',
      spam_report: 'red',
      unsubscribe: 'red',

      queued: 'gray',
      sending: 'gray',
      sent: 'green',
      undelivered: 'red',
      failed: 'red'
    }

    status = this.mapStatus(status);
    return statuses[status] || ''
  }

  $shouldShowHistory = (item) => {
    return item?.logId
  }

  private makeCost(data) {
    return {
      currency: {
        type: "USD",
        fxRate: null
      },
      transitCost: {
        rate: data.price,
        qty: 1,
        total: data.price
      },
      volumeDiscount: {
        type: "percentage",
        percentage: null,
        flatRate: null,
        qty: null,
        total: 0
      },
      subTotal: data.price,
      fuelCost: {
        type: "rpm",
        percentage: null,
        rpm: null,
        qty: null,
        total: 0
      },
      serviceOptions: [],
      negativeMarginReason: null,
      manager: null,
      grandTotal: data.price,
      usdConversion: 0
    }
  }

  async acceptBid(carrier) {
    const params = {
      jobId: this.data.jobId,
      carrierId: carrier.carrierId,
      cost: this.makeCost(carrier)
    };
    const resp = await this.api.POST(`${Const.APIURI_CARRIER_BIDS}/accept-bid`, params).toPromise().catch(err => {
      this.showErr(err);
    });

    if (resp) {
      this.onBtnRefresh();
      this.showDialog(`Carrier has been assigned successfully.<br /><br />
        <a href="${this.gotoDispatch()}" target="_blank">Go to dispatch</a>
      `);
    }
  }

  gotoDispatch() {
    return `${Const.routeAdminDispatchList}/${this.data.jobId}`
  }

  onBtnSendMessage() {
    DialogService.openFormDialog1(SendMessageComponent, {
      nzComponentParams: {
        model: { ...this.data },
        closeOnSuccess: true,
        updateSuccess: (resp) => {
          this.getData()
        },
      },
      nzClassName: "send-message-form modal-xl",
    });
  }

  onBtnEditItem(item) {
    DialogService.openFormDialog1(EditCarrierBidAnswerComponent, {
      nzComponentParams: {
        carrierBidItem: item,
        carrierBidInfo: this.data,
        closeOnSuccess: true,
        updateSuccess: (resp) => {
          this.getData()
        },
      },
      nzClassName: "modal",
    });
  }

  onBtnEditNote(item) {
    DialogService.openFormDialog1(EditNoteComponent, {
      nzComponentParams: {
        carrierBidItem: item,
        carrierBidInfo: this.data,
        closeOnSuccess: true,
        updateSuccess: (resp) => {
          //this.listData[index].carrier = resp.data.assignedCarrier.carrierObj;
          this.getData()
        },
      },
      nzClassName: "modal modal-lg",
    });
  }

  onCheckedChange(event, item) {
    this.data.carriers.map(carrier => carrier['checked'] = event);
    this.reRenderListCarrier(this.data.carriers);
  }

  onBtnBack() {
    this.location.back()
  }
  onBtnEdit() {
    this.router.navigate([this.routeAdminCarrierSales, 'edit', this.data.id])
  }
  onBtnGoToJob() {
    this.router.navigate([this.routeAdminDispatchList, this.data.jobId])
  }
  onBtnDatLoad() {
    this.router.navigate([this.routeAdminCarrierSales, this.data.id, 'dat-load'])
  }
  onBtnPreviousRoute() {
    DialogService.openFormDialog1(ViewPreviousRouteComponent, {
      nzComponentParams: {
        model: this.data,
        closeOnSuccess: true,
        updateSuccess: (resp) => {
          this.getData()
        },
      },
      nzClassName: "view-previous-route-form modal-xl",
    });
  }

  $asCarrierStatusText = (status) => {
    function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
    const statusKey = Object.keys(Const.CarrierStatus).filter((key) => Const.CarrierStatus[key] == status)[0] || "";
    return capitalizeFirstLetter(statusKey);
  };

  $asMoney = (money) => {
    return InputHelper.formatMoney1(money + '');
  };
  $formatDate = (date) => {
    return DateUtil.dateToString(date, Const.FORMAT_GUI_DATETIME_SHORT);
  }
  $formatBidPublicDate = (isoStr) => {
    return DateUtil.displayLocalTime(isoStr, { format: Const.FORMAT_GUI_DATETIME_SHORT });
  }

  openAddCarrierModal() {
    DialogService.openFormDialog1(AddCarrier, {
      nzComponentParams: {
        model: this.model,
        closeOnSuccess: true,
        updateSuccess: (resp) => {
          this.getData()
        },
      },
      nzClassName: "send-mail-form modal-xl",
    });
  }

  onClickPoolCoverage() {
    DialogService.openDialog(AddCarrierPoolByCoverage, {
      nzComponentParams: {
        model: this.data,
        onSave: this.addCarrierByCoverage.bind(this)
        // closeOnSuccess: true,
        // updateSuccess: (resp) => {
        //   this.getData()
        // },
      },
      nzClassName: "add-carrier-pool-by-coverage modal-xxl",
    });
  }

  onAddCarriersByCoverage() {
    DialogService.openDialog(SelectCarrierByServiceArea, {
      nzComponentParams: {
        model: this.data,
        onSave: this.addCarrierByCoverage.bind(this)
        // closeOnSuccess: true,
        // updateSuccess: (resp) => {
        //   this.getData()
        // },
      },
      replaceWrapClassName: true,
      nzClosable: false,
      nzClassName: "add-carrier-pool-by-coverage modal-xxl",
    });
  }

  addCarrierByCoverage(carrierIds: string[] = []) {
    let carriers = carrierIds.map(id => ({ carrierId: id }))
    this.api.POST(`${Const.APIURI_CARRIER_BIDS}/${this.model?.id}/update-carrier`, {
      carriers: carriers
    }).subscribe(
      (response) => {
        this.getData()
        this.showInfo('Add Carriers successfully');
      },
      (err) => {
        this.showErr(err);
      }
    );
  }

  openAddCarrierPoolModal() {
    DialogService.openFormDialog1(AddCarrierPool, {
      nzComponentParams: {
        model: this.model,
        closeOnSuccess: true,
        updateSuccess: (resp) => {
          this.getData()
        },
      },
      nzClassName: "add-carrier-pool-form modal-xl",
    });
  }

  confirmRemoveCarrier() {
    this.confirmDeletion({
      message: `Are you sure you want to remove selected carrier ?`,
      fnOk: () => {
        const carriersRemovedIds = this.data?.carriers?.filter(carrier => carrier.checked).map(it => it.carrierId)
        const carriers = this.listCarrierOriginal?.filter(carrier => !carriersRemovedIds.includes(carrier.carrierId))
        this.api.POST(`${Const.APIURI_CARRIER_BIDS}/${this.model?.id}/update-carrier`, { carriers: carriers }).subscribe(
          (response) => {
            this.keywordSearchName = '';
            this.keywordSearchPoolName = '';
            this.keywordSearchContacts = ''
            this.getData()
            this.showInfo('Remove successfully');
          },
          (err) => {
            this.showErr(err);
          }
        );
      },
      txtBtnOk: 'Remove'
    });
  }

  get isShowWarpOffer() {
    return this?.formInput?.get('isShowBasePrice')?.value;
  }

  carrierBIDType = Object.values(Const.CarrierBidTypes);

  getLabelType(key) {
    switch (key) {
      case Const.CarrierBidTypes.direct: return 'Auto assign when carrier accept load (Direct)';
      case Const.CarrierBidTypes.manual: return 'Manual review (Indirect)';
      default: return 'Manual';
    }
  }

  onItemCheckedChange(event, item) {
    (this.data.carriers?.find(it => it.carrierId == item.carrierId)).checked = event;
  }

  private searchSubject = new Subject<string>();
  onSearch(value: string, type: string) {
    this.data.carriers.map(carrier => {
      if (carrier['checked']) carrier['checked'] = false;
    });
    if (type == 'name') this.keywordSearchName = value;
    if (type == 'contacts') this.keywordSearchContacts = value;
    if (type == 'poolName') this.keywordSearchPoolName = value;
    this.searchSubject.next(value);
  }

  private onFilterData() {
    if (!this.keywordSearchName && !this.keywordSearchContacts && !this.isActiveFilterPool && !this.keywordSearchPoolName) {
      this.reRenderListCarrier(this.listCarrierOriginal);
      return;
    }
    this.reRenderListCarrier(this.listCarrierOriginal.filter(carrier => this.matchedCarrier(carrier)));
  }

  private reRenderListCarrier(carriers) {
    this.data.carriers = carriers.filter(carrier => this.matchedCarrier(carrier));
    this.listCarrierWithGroup = this.sortCarriers(this.groupByDedicatedPool(this.data));
    this.mapOfExpandedData = ListShipmentExpandUtil.processListData(this.listCarrierWithGroup);
    this.listData = this.listCarrierWithGroup;
  }
  private matchedCarrier(carrier) {
    let matched = this.filterName(this.keywordSearchName, carrier)
      && this.filterPoolName(this.keywordSearchPoolName, carrier)
      && this.filterContact(this.keywordSearchContacts, carrier)
      && this.filterPool(this.listPool, carrier);
    return matched
  }

  filterName(inputValue: string, item: any): boolean {
    if (!inputValue) return true;
    inputValue = inputValue.trim()
    let invalid = /[°"§%()\[\]{}=\\?´`'#<>|,;:+_-]+/g;
    inputValue = inputValue.replace(invalid, "");
    const regexName = new RegExp(inputValue, "i");
    return regexName.test(item.name.replace(invalid, ""));
  }

  filterPoolName(inputValue: string, item: any): boolean {
    if (!inputValue) return true;
    inputValue = inputValue.trim()
    let invalid = /[°"§%()\[\]{}=\\?´`'#<>|,;:+_-]+/g;
    inputValue = inputValue.replace(invalid, "");
    const regexName = new RegExp(inputValue, "i");
    return regexName.test((item?.poolName || "").replace(invalid, ""));
  }

  filterContact(inputValue: string, item: any): boolean {
    if (!inputValue) return true;
    inputValue = inputValue.trim();
    let invalid = /[°"§%()\[\]{}=\\?´ `'#<>|,;:+_-]+/g;
    let newValue = inputValue.replace(invalid, "");
    const regex = new RegExp(newValue, "i");
    const regexContact = new RegExp(inputValue, "i");
    return regexContact.test(item.contacts[0].contactName) || regex.test(item.contacts[0].contactEmail) || regex.test(item.contacts[0].contactPhone);
  }

  filterPool(listPool: any[], item: any): boolean {
    if (this.isActiveFilterPool == false) return true;
    const listCheckedPool = listPool?.filter(it => it.checked == true);
    if (item.poolId) return listCheckedPool?.find(it => it.value == item.poolId) != null;
    if (!Utils.isArrayNotEmpty(item.children)) return false;
    const firstChildrenPoolId = item?.children[0]?.poolId;
    return listCheckedPool?.find(it => it.value == firstChildrenPoolId) != null;
  }

  sortPriceCarrier = (a, b) => {
    if (a.price && b.price) return a.price - b.price;
    if (a.children && b.children) return a.children[0].price - b.children[0].price;
    if (a.price || b.children) {
      if (this.sortPriceDirection === 'ascend') return (a.price || 9999999) - (b.children?.[0]?.price || 9999999);
      return (a.price || 0) - (b.children?.[0]?.price || 0);
    }
    if (a.children || b.price) {
      if (this.sortPriceDirection === 'ascend') return (a.children?.[0]?.price || 9999999) - (b.price || 9999999);
      return (a.children?.[0]?.price || 0) - (b.price || 0);
    }
  }

  getListPool() {
    this.listPool = this.model?.pools.map(pool => {
      return {
        label: pool.name,
        value: pool.id ?? pool._id
      }
    });
  }

  isVisibleFilterPool = false;
  isActiveFilterPool = false;
  onChangeFilterPool(event) {
    this.isActiveFilterPool = event?.find(it => it?.checked) ? true : false;
  }

  onBtnResetFilterPool() {
    this.listPool?.forEach(it => {
      if (it.checked) it.checked = false;
    });
    this.isVisibleFilterPool = false;
    this.isActiveFilterPool = false;
    this.onFilterData()
  }

  onBtnFilterPool() {
    this.isVisibleFilterPool = false;
    this.onFilterData();
  }

  shouldShowFilterPool() {
    return this.model?.pools?.length > 0;
  }

  changeSort(event) {
    this.sortPriceDirection = event;
    this.reRenderListCarrier(this.listCarrierOriginal);
  }

  async getDatLoad() {
    const resp = await this.api.POST(`${Const.APIURI_CARRIER_BIDS}/get-dat-load-info`, {
      id: this.carrierBidId
    }).toPromise().catch(err => {
      this.showErr(err);
      this.shouldShowBtnDat = false;
    });
    this.shouldShowBtnDat = resp?.data?.isShow || false;
    this.datPostExist = resp?.data?.isCreated || false;
    return;
  }

  public shouldDisableRemoveButton() {
    const carriersRemoved = this.data?.carriers?.filter(carrier => carrier.checked);
    for (let carrier of carriersRemoved) {
      if (carrier?.state == Const.CarrierBidState.Accepted || carrier?.state == Const.CarrierBidState.PlacedBid) return true;
      if (carrier?.lastSent?.when) return true;
      if (carrier.price) return true;
    }
    return false;
  }

  public isIndeterminate() {
    return this.checkedStatus === CHECKSTATUS.AT_LEAST_ONE_ITEM_CHECKED
  }

  public isCheckedAll() {
    return this.checkedStatus === CHECKSTATUS.ALL_ITEMS_CHECKED
  }

  public isNoChecked() {
    return this.checkedStatus === CHECKSTATUS.NO_ITEM_CHECKED
  }

  public isLoadingGhostShipment: boolean = false;
  public totalShipmentsItems
  getGhostShipmentInfo() {
    this.isLoadingGhostShipment = true;
    this.api.POST(`${Const.APIV2(Const.APIURI_MARKETPLACE)}/get_packaging_by_ids`, { ids: [this.data.jobId] }).subscribe(
      res => {
        const data = res.data.list_data?.[0];
        this.totalShipmentsItems = data.totalShipmentsItems;
        this.isLoadingGhostShipment = false;
      }
    )
  }
  isMarketPlaceLoad() {
    return this.data?.job?.type == WarpConst.JobType.ghost && this.data?.job?.source == WarpConst.JobSources.marketplace;
  }
  public getFirstCommodity() {
    if (this.totalShipmentsItems?.commodity?.length > 1)
      return `${this.totalShipmentsItems.commodity[0]}, ${this.totalShipmentsItems.commodity[1]}`;
    else if (this.totalShipmentsItems?.commodity?.length)
      return this.totalShipmentsItems.commodity[0];
    else return "N/A";
  }

  public getAllCommodities() {
    return this.totalShipmentsItems?.commodity?.join(', ');
  }
}
