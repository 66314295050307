import { Component } from "@angular/core";
import { Const } from "@const/Const";
import { DateUtil } from "@services/date-utils";
import { Utils } from "@services/utils";
import { BaseFormDrawer } from "@app/drawers/base-form";
import { MasterData } from "@services/master.data";
import { ApiMethod } from "@app/enum";

@Component({
  selector: "[import-manifest]",
  templateUrl: "./view.html",
  styleUrls: ["./index.scss"],
})
export class ImportManifest extends BaseFormDrawer {

  protected formGroupDeclaration: FormGroupDeclaration = {
    warehouseId: {label: 'Warehouse', required: true},
    mblNumber: {label: 'MBL number', required: true, placeHolder: 'Enter BOL Number'},
    manifestFile: {label: 'Manifest file', required: true, type: 'uploadFile'},
    clientId: {label: 'Customer', required: true, placeHolder: 'Please select customer'},
    intendedInjectionDate: {label: 'Intended injection date', required: true},
  };
  
  public urlTemplateManifest;
  public listOfCrossdockWarehouse = [];
  public isLoadingWarehouses = false;
  constructor() {
    super();
  }

  ngOnInit(): void {
    let listTemplates = MasterData.getTemplateManifest();
    if (Utils.isArrayNotEmpty(listTemplates)) {
      this.urlTemplateManifest = listTemplates[0];
    }
    if (this.model) {
      this.formGroupDeclaration.manifestFile.required = false;
    }
    super.ngOnInit();
    this.getCrossdockWarehouseList();
  }

  private getCrossdockWarehouseList() {
    this.isLoadingWarehouses = true;
    this.api.GET(Const.APIURI_SELECT_WAREHOUSES).subscribe(
      resp => {
        if (resp?.data?.list_data && resp.data.list_data.length) {
          this.listOfCrossdockWarehouse = resp.data.list_data.map((item: any) => {
            let { id, warpId, name } = item;
            return {
              id, warpId, name, label: `${warpId} - ${name}`
            }
          });
        }
        this.isLoadingWarehouses = false;
      }, err => {
        this.isLoadingWarehouses = false;
      }
    )
  }

  get isCreateNew(): boolean {
    return !this.model;
  }

  get headerTxt() {
    return this.model ? 'Edit Manifest' : 'Import Manifest';
  }

  get labelSubmit() {
    return this.model ? 'Save' : 'Import';
  }

  protected buildUrl(method: ApiMethod): string {
    let url = this.getApiUrl();
    if (!url) return url;
    let id = (<any>this.model)?.id || (<any>this.model)?._id;
    if (id) {
      url = `${url}/${id}`;
    }
    return url;
  }

  protected getApiUrl(): string {
    return `${Const.APIURI_WAREHOUSE_MANIFEST}`;
  }

  public onClose() {
    this.nzDrawer?.close();
  }

  public get labelSelectFile(): string {
    let key = 'manifestFile';
    return this.hasAttachedFile(key) ? this.getFileDesc(key) : null;
  }

  protected getFormData_JSON(isCreateNew: boolean) {
    let json: any = super.getFormData_JSON(true);
    json.intendedInjectionDate = DateUtil.format(json.intendedInjectionDate, Const.FORMAT_GUI_DATE_V3);
    return json;
  }

  protected beforeBindModel(bindData: any = {}) {
    delete bindData.manifestFile;
    return bindData;
  }

  // onBtnDowloadTemplate() {
  //   this.downloadCSV(this.urlTemplateManifest);
  // }

  // async downloadCSV(url) {
  //   try {
  //     const response = await fetch(url);
  //     const blob = await response.blob();
  
  //     const link = document.createElement('a');
  //     link.href = window.URL.createObjectURL(blob);
  //     link.download = 'template_manifest.csv';
  
  //     link.click();
  
  //     window.URL.revokeObjectURL(link.href);
  //   } catch (error) {
  //     console.error('Error downloading CSV:', error);
  //   }
  // }

}
