<div class="form-header no-padding-bottom">
</div>
<div class="top20" nz-row>
  <div nz-col [nzMd]="6" [nzXs]="24">
    <div class="desktop">
      <div class="f20 bold">Select Carriers</div>
      <div>Please click the map to select Service Areas.</div>
      <div class=" bottom20 selected-area-title">Selected Service Area{{featureList?.length && featureList.length > 1 ?
        's' : ''}}:
        
        <button style="margin-left: 8px" nz-button nzType="primary" nzSize="small" (click)="clearSelected()">
          <span nz-icon nzType="clear" nzTheme="outline"></span>
          Clear</button>
      </div>
      <div class="selected-area">
        <div class="flex" style="flex-flow: wrap; overflow-y: scroll;">
          <ng-container *ngFor="let item of featureList">
            <div class="label-item"><span style="cursor: pointer; margin-right: 5px;"
                (click)="onSelectHightlight(item)">{{item.name}}</span><span *ngIf="item.data?.properties?.SHORT_COUNTRY">({{item.data?.properties?.SHORT_COUNTRY}})</span><span class="icon-x" nz-icon nzType="close"
                nzTheme="outline" (click)="onDeletedSelectedItem(item)"></span></div>
          </ng-container>
        </div>
      </div>
      <div class="bottom20 selected-area-title top10">{{matchedCarriers?.length}} Matched
        Carrier{{matchedCarriers?.length && matchedCarriers.length > 1 ? 's' : ''}}:
        <button [disabled]="syncLoading || !featureList.length" style="margin-left: 8px" nz-button nzType="primary"
        nzSize="small" (click)="getCarriersInAreas()">
        <span *ngIf="!syncLoading" nz-icon nzType="sync" nzTheme="outline"></span>
        <span *ngIf="syncLoading" nz-icon [nzType]="'sync'" [nzSpin]="true"></span>
        Sync</button></div>

      <div class="match-carrier">
        <div class="flex" style="flex-flow: wrap; overflow-y: scroll;">
          <ng-container *ngFor="let item of matchedCarriers">
            <div class="label-item"><span style="cursor: pointer; margin-right: 5px;">
                {{item?.basicInfo?.name ?? item?.basicInfo?.mc ?? item?.basicInfo?.dot}}
              </span><span class="icon-x" nz-icon nzType="close" nzTheme="outline"
                (click)="onDeletedSelectedItem(item)"></span></div>
          </ng-container>
        </div>
      </div>
      <div class="top20 bottom20 text-center" style="margin-right: 16px">
        <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }">
          <div class="gutter-row" nz-col [nzXs]="12">
            <button style="width: 100%" nz-button nzShape="round" (click)="onBtnCancel()">Cancel</button>
          </div>
          <div class="gutter-row" nz-col [nzXs]="12">
            <button [disabled]="!matchedCarriers?.length" style="width: 100%" nz-button nzShape="round" nzType="primary"
              (click)="onBtnContinue()">Submit</button>
          </div>
        </div>
      </div>
    </div>
    <div class="mobile bottom20">
      <div>Please click the map to select your service area.</div>
      <ng-container *ngIf="!featureList?.length">
        <div class="bold f20">Selected service area...</div>
      </ng-container>
      <ng-container *ngIf="featureList?.length">
        <div class="bold f20">{{featureList?.length}} areas selected.</div>
      </ng-container>
    </div>
  </div>
  <div nz-col [nzMd]="18" [nzXs]="24">
    <div class="map-controls">
      <div class="parent-control flex">
        <div class="left-button">
          <div class="flex">
            <div class="item-btn state" (click)="onSelectBtn(selectBtnValues.state)"
              [ngClass]="{select: isSelectButon(selectBtnValues.state)}">State</div>
            <div class="item-btn metro" (click)="onSelectBtn(selectBtnValues.metro)"
              [ngClass]="{select: isSelectButon(selectBtnValues.metro)}">Metro</div>
          </div>
          <ng-container *ngIf="buttonSelect == selectBtnValues.state">
            <div class="item-btn select-all" (ngModelChange)="onChangeSelectAll($event)" nz-checkbox
              [(ngModel)]="isSelectAll">Select All</div>
          </ng-container>
        </div>
        <div class="right-button desktop">
          <!-- <div class="item-btn" (click)="onSelectBtn(selectBtnValues.draw)" [ngClass]="{select: isSelectButon(selectBtnValues.draw)}"> <img src="assets/img/draw.svg" />Draw</div> -->
          
          <div class="item-btn" (click)="onSelectBtn(selectBtnValues.radius)"
            [ngClass]="{select: isSelectButon(selectBtnValues.radius)}"> <img
              src="assets/img/blur_circular.svg" />Radius</div>
          <div class="item-btn" (click)="onSelectBtn(selectBtnValues.point)"
            [ngClass]="{select: isSelectButon(selectBtnValues.point)}"><img src="assets/img/polyline.svg" /> Point</div>
          <div class="item-btn"
            nz-popover
            [nzPopoverContent]="mapHelp"
            nzPopoverPlacement="left"
          ><span nz-icon nzType="question-circle" nzTheme="outline" style="margin-right: 5px;"></span> Help
            <ng-template #mapHelp>
              <div style="width: 300px;">
                <img style="width: 300px;" src="/assets/img/help_mapbox.gif" />
                <div style="margin-top: 15px;">To zoom in and out of a circle, please follow these steps:</div>
                <div>1. Click on the dot inside the circle.</div>
                <div>2. Move your mouse over the circle and hold down the left mouse button.</div>
                <div>3. While holding the left mouse button, move your mouse to drag the circle and adjust its size.</div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
    <div class="map-controls-mobile">
      <div class="right-button mobile">
        <!-- <div class="item-btn" (click)="onSelectBtn(selectBtnValues.draw)" [ngClass]="{select: isSelectButon(selectBtnValues.draw)}"> <img src="assets/img/draw.svg" />Draw</div> -->
        <div class="item-btn" (click)="onSelectBtn(selectBtnValues.radius)"
          [ngClass]="{select: isSelectButon(selectBtnValues.radius)}"> <img src="assets/img/blur_circular.svg" /></div>
        <div class="item-btn" (click)="onSelectBtn(selectBtnValues.point)"
          [ngClass]="{select: isSelectButon(selectBtnValues.point)}"><img src="assets/img/polyline.svg" /></div>
      </div>
    </div>
    <div #carrierCoverageArea id="add-carrier-by-coverage" style="width: 100%; margin-bottom: 20px;"></div>
    <div class="mobile">
      <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }">
        <div class="gutter-row" nz-col [nzXs]="12">
          <button style="width: 100%" nz-button nzShape="round" (click)="onBtnCancel()">Back</button>
        </div>
        <div class="gutter-row" nz-col [nzXs]="12">
          <button style="width: 100%" nz-button nzShape="round" [disabled]="!matchedCarriers?.length" nzType="primary"
            (click)="onBtnContinue()">Continue</button>
        </div>
      </div>
    </div>
  </div>