<div class="carrier-selection">
  <div class="group-header">
    <div class="f20 bottom20">View Previous Route</div>
    <span nzDanger (click)="closeDialog()">
      <i nz-icon nzType="close" nzTheme="outline"></i>
    </span>
  </div>

  <div class="content">
    <nz-table #nzTable nzBordered="true" [nzData]="data.list_data" nzSize="small" [nzLoading]="isLoading"
      [nzLoadingIndicator]="tplLoading" [nzNoResult]="tplNoData" [nzSize]="'default'">
      <ng-template #tplNoData>
        <div *ngIf="!isLoading" class="nodata"><i nz-icon nzType="search" nzTheme="outline"></i>No data</div>
      </ng-template>
      <ng-template #tplLoading>
        <div class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
      </ng-template>
      <thead>
        <tr>
          <th nzColumnKey="warpId">Warp ID</th>
          <th nzColumnKey="carrier">Carrier</th>
          <th nzColumnKey="pickDt.time" style="min-width: 250px;">Pickup Time & Location</th>
          <th nzColumnKey="dropDt.time" style="min-width: 250px;">Dropoff Time & Location</th>
          <th nzColumnKey="revenue">Revenue</th>
          <th nzColumnKey="carrierCost">Carrier Cost</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of nzTable.data; let i = index">
          <td>
            <div>
              <ng-container *ngFor="let orderIndex of [0]">
                  <a *ngIf="orderIndex < item.shipmentLinks?.length" class="link" target="_blank"
                    [routerLink]="getRouterLink(item, orderIndex)"
                    [fragment]="getRouterFragment(item, orderIndex)">{{item.shipmentLinks[orderIndex].warpId}}</a>
                </ng-container>
                <ng-container *ngIf="item.shipmentLinks?.length > 1">
                  <a class="link" (click)="seeAllOrder(i)" style="margin-left: 5px;">(+{{item.shipmentLinks.length-1}})</a>
                </ng-container>
            </div>
          </td>
          <td>{{ item.carrier[0]?.basicInfo.name || "-" }}</td>
          <td >
            <div style="white-space: pre-wrap;">{{item.pickWindow}}</div>
            <div class="arr-client-address">
              <span *ngFor="let client of item.clients">{{client.name  + ', '}}</span>
              {{item.pickAddress}}
            </div>
            <div></div>
          </td>
          <td >
            <div style="white-space: pre-wrap;">{{item.dropWindow}}</div>
            <div class="arr-client-address">
              <span *ngFor="let client of item.clients">{{client.name + ', '}}</span>
              {{item.dropAddress}}
            </div>
          </td>
          <td>
            <div>
              {{item.totalShipmentsCost}}
              <i nz-icon nzType="info-circle" nzTheme="outline"
                nzTooltipPlacement="bottom"
                nzTooltipOverlayClassName="tooltip-info"
                style="margin-left: 10px;" class="clickable" nz-tooltip [nzTooltipTitle]="tplCostItems"></i>
              <ng-template #tplCostItems>
                <ng-container
                  [ngTemplateOutlet]="tplCostItemsInner"
                  [ngTemplateOutletContext]="{cost: item.totalShipmentsCost, units: item.totalShipmentsItems.units, weight: item.totalShipmentsItems.weight}">
                </ng-container>
                <ng-template #tplCostItemsInner let-cost="cost" let-units="units" let-weight="weight">
                  <div class="tplCostItems">
                    <div class="value">{{cost}}</div>
                    <div class="description">Add up grand total for all shipments on the route</div>
                    <div class="value">{{weight}}</div>
                    <div class="description">Add up weight for all shipments on the route</div>
                    <div class="value">{{units}}</div>
                    <div class="description">Add up units for all shipments on the route</div>
                  </div>
                </ng-template>
              </ng-template>
            </div>
            <div class="shipment-items">
              {{item.totalShipmentsItems?.weight}}
              <ng-container *ngIf="item.totalShipmentsItems?.units?.length">, {{item.totalShipmentsItems.units}}</ng-container>
            </div>
          </td>
          <td >
              <div>{{item.carrierCost ? item.carrierCost: '-'}}</div>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</div>
