import { Component, OnInit } from '@angular/core';
import { Task as TaskModel } from '@wearewarp/types/data-model';
import { BaseFormDialog1 } from '@dialogs/base-form-dlg1';
import { ApiService } from "@services/api.service";
import { Const } from "@const/Const";
import { DateUtil } from "@services/date-utils";
import { InputHelper } from "@app/services/input-helper";
import { DrawerService } from "@app/drawers/drawer.service";
import { SeeAllOrder } from '@app/admin/components/see-all-orders/see-all-orders';

const API_URLS = {
  GET_CARRIER_BIDS: Const.APIURI_CARRIER_BIDS,
};

@Component({
  selector: 'app-view-previous-route',
  templateUrl: './view-previous-route.component.html',
  styleUrls: ['./view-previous-route.component.scss']
})
export class ViewPreviousRouteComponent extends BaseFormDialog1 implements OnInit {

  public isLoading = false;
  public data: any = {};

  constructor(api: ApiService) {
    super();
    this.api = api;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.initData()
  }

  public get carrierBidId() {
    return this.model.id
  }

  initData(){
    if (!this.carrierBidId) return;
    this.createFormInput();
    this.setEnableFormGroup(false);
    this.isLoading = true;

    this.api.GET(`${API_URLS.GET_CARRIER_BIDS}/${this.carrierBidId}/get_list_previous_routes`).subscribe(
      (response) => {
        const data = response.data || {};
        //convert data
        this.data = this.onGetDataSucceeded(data);

        this.isLoading = false;
        this.model = data;
      },
      (err) => {
        this.isLoading = false;
        this.showErr(err);
      }
    );
  }

  public getRouterLink(job, index) {
    return [this.routeAdminShipmentList, job.shipmentLinks[index].orderId]
  }

  public getRouterFragment(job, index) {
    let item: any = job.shipmentLinks[index];
    return item.shipmentId;
  }

  public seeAllOrder(jobIndex) {
    let job = this.data.list_data[jobIndex];
    DrawerService.openFormDrawer(SeeAllOrder, {
      nzContentParams: {
        job: job,
      },
    });
  }

  protected onGetDataSucceeded(res) {
    let data = []
    for (let item of res.list_data) {
      data.push(this.processItem(item));
    }
    return {
      list_data: data
    }
  }

  protected processItem(item) {
    item.shipmentLinks = [];
    const shipments = item.shipments;
    let dic = {};
    for (let task of item.tasks || []) {
      let sh = shipments.find(sh => sh.id === task.shipmentId);
      let key = `${sh?.orderId}_${task.shipmentId}`;
      let shipment: any = {
        warpId: sh?.warpId,
        shipmentId: task.shipmentId,
        orderId: sh?.orderId,
      };
      if (!dic[key]) {
        dic[key] = shipment;
        item.shipmentLinks.push(shipment);
      }
    }
    item.pickWindow = this.displayWindow(Object.assign({time: item.earliest_time, timeTo: item.latest_time}, item.pickDt));
    item.dropWindow = this.displayWindow(item.dropDt);

    return item;
  }

   // time, timeTo are ISO string 2022-03-29T18:19:10.000Z
   private displayWindow(obj: {time: string, timeTo: string, timezone: string, isAppointment: boolean}) {
    if (!obj || (!obj.time && !obj.timeTo)) return 'N/A';
    let formatDateTime = 'ddd, MMM D . h:mm a';
    let timeFrom = obj.time;
    let timeTo = obj.timeTo;
    timeFrom = DateUtil.displayLocalTime(timeFrom, {timezone: obj.timezone, format: formatDateTime});
    timeTo = DateUtil.displayLocalTime(timeTo, {timezone: obj.timezone, format: formatDateTime});
    let arr1 = timeFrom.split('.');
    let arr2 = timeTo.split('.');
    let isSameDay = arr1[0] == arr2[0];
    if (isSameDay) {
      timeTo = arr2[1]?.trim();
    }
    let str = `${timeFrom} - ${timeTo}`;
    if (obj.isAppointment) {
      str += '\n(Appointment Scheduled)';
    }
    return str;
  }

  $asMoney = (money) => {
    return InputHelper.formatMoney1(money +'');
  };
}
