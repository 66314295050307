import { BaseComponent } from "@abstract/BaseComponent";
import DeliveryMap from "../map";
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";

@Component({
    selector: 'map-container',
    templateUrl: './index.html',
    styleUrls: ['./index.scss',]
})
export class MapContainer extends BaseComponent {
    _map: DeliveryMap
    @Input() id: string = 'NONE'
    @Input() mapStyle: string = null
    @Input() showConfig: boolean = true
    @Input() config: any = {}

    get map() {
        return this._map
    }
    @Input() set map(v) {
        if (v != null)
            this._map = v
    }
    @Output() mapChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() selectShipment: EventEmitter<any> = new EventEmitter<any>();
    @Output() selectRoute: EventEmitter<any> = new EventEmitter<any>();
    @Output() selectWarehouse: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('mapboxContainer', { static: true }) mapboxContainer: ElementRef;
    @ViewChild('deckCanvas', { static: true }) deckCanvas: ElementRef;
    isDestroy=false
    ngOnInit(): void {
        setTimeout(() => {
          if(!this.isDestroy){
            if (!this._map) {
              this.map = new DeliveryMap(this.id, this.mapboxContainer, this.deckCanvas, DeliveryMap.defaultInitialViewState, Object.assign({style: this.mapStyle}, this.config || {}))
              this.mapChange.emit(this._map)
              this.map.onPickObject = this.onMapPickObject.bind(this)
            } else {
              this.map?.loadContainer(this.mapboxContainer, this.deckCanvas)
          }
        }}, 0)
    }

    ngOnDestroy(): void {
      this.isDestroy=true;
      setTimeout(() => {
        this.map?.destroy()
        }, 0)
    }

    onMapPickObject({coordinate, object}) {
        const { type, shipment, warpId, route, warehouse } = object || {}
        if (type === 'SHIPMENT' && warpId) {
            if (shipment) {
                this.selectShipment.emit(shipment)
            }
        }
        if (type === 'ROUTE' && warpId) {
            if (route) {
                this.selectRoute.emit(route)
            }
        }
        if (type === 'ROUTE' && warpId) {
            if (route) {
                this.selectRoute.emit(route)
            }
        }
        if (type === 'WAREHOUSE' && warehouse) {
            this.selectWarehouse.emit(warehouse)
        }
    }
}
