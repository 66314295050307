import { Component, forwardRef, Inject, Injector, Input, NgZone } from '@angular/core';
import { AbstractControl, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { Const } from '@const/Const';
import { DialogService } from '@dialogs/dialog.service';
import { ApiService } from '@services/api.service';
import { Utils } from '@services/utils';
import { countryListAlpha2 } from './country-code'
import { NzNotificationService } from "ng-zorro-antd/notification";
import { BaseInputComponent } from '../base-custom-input';
import { MasterData } from '@services/master.data';
import { SelectAddressFromMap } from './select-address-from-map';
import { AddressUS } from '@wearewarp/types/data-model';

@Component({
  selector: 'form-address',
  templateUrl: './form-address.component.html',
  styleUrls: ['./form-address.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => FormAddressComponent), multi: true }],

})
export class FormAddressComponent extends BaseInputComponent {
  public country = countryListAlpha2;
  public placeHolder = 'Enter address';
  public isLoading: boolean = false;
  public options: Array<{ value: any, text: string }> = []

  @Input() showDetailPopup?: boolean | undefined;
  @Input() zipcode: string | undefined = undefined;

  private _disableInput: boolean | undefined;
  @Input() set disableInput(value: boolean | undefined) {
    if(value!=undefined) this.isDisabled = value;
    this._disableInput = value;
  }

  get disableInput(): boolean | undefined {
    return this._disableInput;
  }

  nzFilterOption = (): boolean => true;

  constructor(
    @Inject(Injector) protected injector: Injector,
    protected notification: NzNotificationService,
    protected api: ApiService,
    protected ngZone: NgZone

  ) {
    super(injector)
  }

  selectEvent = { focus: false, hover: false };
  onSelectEvent(eventName, bool) {
    this.selectEvent[eventName] = bool
  }

  get shouldShowDetail() {
    if (typeof this.showDetailPopup == "boolean")
      return this.showDetailPopup;
    if (!this.selected?.metadata) return false;
    if (this.selectEvent.focus) return false
  }

  get value() {
    return this.selected;
  }

  set value(value: any) {
    this.processValue(value);
  }

  _selectedCountry: string = 'US'
  get selectedCountry() {
    return this._selectedCountry;
  }
  set selectedCountry(value) {
    this._selectedCountry = value;
    this.value = undefined
  }

  isAddressObject(location: AddressUS): boolean {
    if (!location.street || !location.city || !location.state || !location.zipcode) return false;
    return true;
  }

  hasMetadata(location: AddressUS): boolean {
    return !!location?.metadata?.latitude && !!location?.metadata?.longitude;
  }

  private setLoading(v: boolean) {
    this.ngZone.run(() => {
      this.isLoading = v;
    });
  }

  private changeDisabledValue(v:boolean){
    this.ngZone.run(() => {
      if(this.disableInput!=undefined) 
      this.isDisabled = this.disableInput;
      else this.isDisabled = v;
    });
   
  }

  private isExternalAddress(location: AddressUS): boolean {
    return location?.metadata?.isExternal == true;
  }

  async processValue(selected) {
    if(this.isExternalAddress(selected)){
      this.setLoading(false);
      this.changeDisabledValue(true);
      if(!selected?.metadata?.externalId){
        selected.metadata.externalId = Utils.generateULID()
      }
      return;
    }
    let isDisableEditing = this.isDisabled;
    this.setLoading(true);
    this.isDisabled = false;
    //nếu type là string thì cố gắng search trước và lấy kết quả đầu tiên
    if (typeof selected == "string") {
      const searchResult = await this.searchLocation(selected);
      if (!searchResult.length) {
        //nếu không search được kết quả nào thoả mãn thì loại bỏ luôn kết quả và báo lỗi.
        this.value = {};
        this.setLoading(false);
        this.changeDisabledValue(isDisableEditing);
        return;
      }
      selected = searchResult[0];
    }
    if (Utils.isObjectNotEmpty(selected)) {
      const shouldQueryLocationDetail = selected.placeId ||                     // kết quả search từ google api
            (this.isAddressObject(selected) && !this.hasMetadata(selected))     // thiếu metadata
      if (shouldQueryLocationDetail) {
        try {
          selected = await this.getDetail(selected);
        } catch (e) {
          console.error(e);
          this.options = [];
          this.value = {}
          this.setLoading(false);
          this.changeDisabledValue(isDisableEditing);
          return;
        }
      }
      if (!selected || !selected.metadata) {
        this.value = {}
        this.setLoading(false);
        this.changeDisabledValue(isDisableEditing);
        return;
      }
      //bổ sung selected vào options để có thể hiển thị trên UI
      if (this.options.length == 0) {
        this.buildOptions([selected])
      }
    }

    if (!this.isSameValue(this.selected, selected)) {
      this.selected = selected;
      this._selectedCountry = selected?.country?.alpha2Code || selected?.countryAlpha2Code || selected?.country || this._selectedCountry;
      this.updateOption(this.selected)
      this.onChange(this.selected);
      this.onTouch(this.selected);
    }

    this.setLoading(false);
    this.changeDisabledValue(isDisableEditing);
  }

  isSameValue(value1, value2) {
    if ((!value1 && value2) || (!value2 && value1)) return false;
    if (!value1 && !value2) return true;
    return this.isSelectedLocation(value1, value2)
  }

  /**
   * Override
   * @param value 
   */
  writeValue(value: any): void {
    //khi update value từ form vào thì xoá hết list options đi để build lại.
    this.buildOptions([])
    super.writeValue(value);
  }

  ngOnInit(): void {
    if (this.zipcode) {
      this.placeHolder = `Please enter an address that matches zip code ${this.zipcode}`;
    }
    super.ngOnInit();
    this.control.addValidators(this.validateAddress);
  }

  validateAddress = (control: AbstractControl): ValidationErrors | null => {
    if (!this.selected || Object.keys(this.selected).length == 0) return null;
    if (this.zipcode && this.selected?.zipcode !== this.zipcode) {
      return {
        "invalid-address": {
          "en": `Please enter an address that matches zip code ${this.zipcode}`,
        },
      };
    }
    if (!this.selected?.metadata || !this.selected?.street) {
      //return error
      return {
        "invalid-address": {
          "en": "Invalid address, Please try again!",
        },
      };
    }
    return null;
  };

  async searchLocation(keyword) {
    try {
      const params = {
        address: keyword,
        country: this.selectedCountry
      }

      const response = await this.api.GET(`${Const.APIURI_WAREHOUSES}/get/search-locations?${new URLSearchParams(params).toString()}`).toPromise();
      const locations = response?.data?.list_data || []
      this.ngZone.run(() => { this.isLoading = false });
      this.buildOptions(locations);
      return locations
    }
    catch (e) { }
    this.ngZone.run(() => { this.isLoading = false });
  }

  private timeout;
  search(value: string) {
    clearTimeout(this.timeout);
    if (!value) {
      this.ngZone.run(() => { this.isLoading = false });
      return;
    }

    //debounce 300ms
    this.timeout = setTimeout(async () => {
      await this.searchLocation(value);
    }, 300)
  }

  async getDetail(location: any) {
    if (!location) return;

    const response = await this.api.POST(`${Const.APIURI_WAREHOUSES}/get-location-detail`, location).toPromise();
    location = response?.data || {}
    return location
  }

  //sử dụng để update lại info của option. bổ sung thêm zipcode, streetname... sau khi fetch detail
  updateOption(newOption) {
    for (let index in this.options) {
      const option = this.options[index];
      if (this.isSameValue(option, newOption)) {
        this.options[index] = newOption
      }
    }

    this.buildOptions(this.options);
  }

  buildOptions(locations) {
    let options = [];
    for (let location of locations) {
      options.push(location)
    }
    this.options = [...options]
  }

  getLabelOption(option) {
    if (!option) return
    if (option.formatedAddress) return option.formatedAddress;
    return MasterData.getAddressText(option);
  }
  isSelectedLocation(location1, location2) {
    if (!location1 || !location2 || !Utils.isObjectNotEmpty(location1) || !Utils.isObjectNotEmpty(location2)) return false;
    return (
      (location1?.placeId && location1?.placeId == location2?.placeId)
      ||
      (location1?.formatedAddress && location1?.formatedAddress == location2?.formatedAddress)
      ||
      (location1?.zipcode
        && location1?.zipcode == location2?.zipcode
        && location1?.street == location2?.street
        && location1?.city == location2?.city
        && location1?.state == location2?.state
      )
    )
  }


  copyAddress(event) {
    let content = this.getLabelOption(this.selected);

    //nếu giữ phím ctrl hoặc Command rồi click thì sẽ copy JSON. Phục vụ debug và dev
    if (event.ctrlKey || event.metaKey) {
      content = JSON.stringify(this.selected, null, 2)
    }

    Utils.copyTextToClipboard(content, () => {
      this.notification.success('Copy address', 'Address has been copied to clipboard')
    })
  }

  onBtnSelectAddressFromMap() {
    this.modalService.confirm({
      nzTitle: `When you can't find the address, you can enter it manually.<br>However, make sure you are entering a correct address as well as selecting the correct coordinates.<br>Are you sure you want to enter it manually?`,
      nzClosable: false,
      nzMaskClosable: false,
      nzCentered: true,
      nzOkText: 'Yes',
      nzClassName: 'modal modal-lg',
      nzOnOk: () => {
        DialogService.openFormDialog1(SelectAddressFromMap, {
          nzComponentParams: {
            closeOnSuccess: true,
            onSave: (data) => {
              this.writeValue(data)
            }
          },
          nzClassName: "modal modal-xxl",
          nzCentered: true,
        });
      },
      nzCancelText: 'No',
    });
  }
}
