import { Component } from "@angular/core";
import { Const } from "@const/Const";
import { BaseFormItem } from "@app/admin/base/form-item";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "[list-header-filter]",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})
export class ManifestListHeaderFilter extends BaseFormItem {

  public search: string = null
  public page: number = 1;
  public loaded: number = 0;
  public listOfCrossdockWarehouse = [];
  public isLoadingWarehouses = false;
  public warehouseId;

  constructor(protected activatedRoute: ActivatedRoute) {
    super();
    this.activatedRoute.queryParams.subscribe((p) => {
      this.search = p.search;
      this.loaded = p.loaded || 0;
      this.page = p.page || 1;
      if (p.filter) {
        let condition = JSON.parse(p.filter)
        if (condition?.warehouseId) {
          this.warehouseId = condition.warehouseId;
        }
      }
    });
  }

  ngOnInit(): void {
    this.getCrossdockWarehouseList();
  }

  private getCrossdockWarehouseList() {
    this.isLoadingWarehouses = true;
    this.api.GET(Const.APIURI_SELECT_WAREHOUSES).subscribe(
      resp => {
        if (resp?.data?.list_data && resp.data.list_data.length) {
          this.listOfCrossdockWarehouse = resp.data.list_data.map((item: any) => {
            let { id, warpId, name } = item;
            return {
              id, warpId, name, label: `${warpId} - ${name}`
            }
          });
        }
        this.isLoadingWarehouses = false;
      }, err => {
        this.isLoadingWarehouses = false;
      }
    )
  }

  doSearch(s) {
    this.search = s;
    this.page = 1;
    this.loadData();
  }

  onChangeWarehouse(value) {
    this.page = 1;
    this.loadData();
  }

  loadData() {
    this.routeWithQueryUrl(this.buildQuery(), true);
  }

  private buildQuery(): any {
    let q = { page: this.page || 1, loaded: Date.now() };
    if (this.search) {
      q["search"] = this.search;
    }
    let condition: any = {};
    if (this.warehouseId) {
      condition.warehouseId = this.warehouseId;
    }
    const f = JSON.stringify(condition);
    if (f.length > 2) {
      q['filter'] = f;
    }
    return q;
  }

}
