<form class="form-detail" [formGroup]="formInput" nz-form>
  <div class="group no-padding">
    <!-- header -->
    <div class="group-header" style="display: flex; align-items: center">
      <div style="text-wrap: nowrap !important">Label Template</div>
      <div style="width: 100%"></div>
      <ng-container *ngIf="requirePermissions([PermissionCode.client.update])">
        <!-- edit button -->
        <button nz-button (click)="onBtnEdit()" class="btn-primary" *ngIf="!isEditing">
          <i nz-icon nzType="edit" nzTheme="outline"></i>
          Edit
        </button>
        <!-- save button -->
        <button
          nz-button
          (click)="onBtnSave()"
          class="btn-primary"
          *ngIf="isEditing"
          [disabled]="onProgress || !needUpdate"
          [nzLoading]="onProgress"
        >
          <i nz-icon nzType="save" nzTheme="outline"></i>
          Save
        </button>
      </ng-container>
    </div>
    <!-- form -->
    <div class="group-content">
      <div class="tdm-row cell-spacing">
        <div class="tdm-col tdm-col-5" *ngFor="let key of ['palletTemplateId', 'sortingTemplateId']">
          <div>
            <div class="form-label-v2">
              {{getLabel(key)}}
              <span *ngIf="isRequired(key)" class="label-mark-required"></span>
            </div>
            <nz-form-item>
              <nz-form-control>
                <ng-container *ngIf="key === 'palletTemplateId'">
                  <nz-select
                    nzBackdrop="true"
                    style="width: 100%"
                    nzShowSearch
                    nzAllowClear
                    [nzLoading]="isLoadingTemplates"
                    [nzPlaceHolder]="getPlaceHolder(key)"
                    [formControlName]="key"
                  >
                    <nz-option
                      *ngFor="let label of palletTemplates"
                      [nzLabel]="label.name"
                      [nzValue]="label.id"
                    ></nz-option>
                  </nz-select>
                </ng-container>
                <ng-container *ngIf="key === 'sortingTemplateId'">
                  <nz-select
                    nzBackdrop="true"
                    style="width: 100%"
                    nzShowSearch
                    nzAllowClear
                    [nzLoading]="isLoadingTemplates"
                    [nzPlaceHolder]="getPlaceHolder(key)"
                    [formControlName]="key"
                  >
                    <nz-option
                      *ngFor="let label of sortingTemplates"
                      [nzLabel]="label.name"
                      [nzValue]="label.id"
                    ></nz-option>
                  </nz-select>
                </ng-container>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
